import React, { useState, useEffect } from "react"
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
} from "@mui/material"
import axios from "axios"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

const MyProfile = () => {
  const [user, setUser] = useState({})
  const [isEditing, setIsEditing] = useState(false)
  const [otp, setOtp] = useState("")
  const [isOtpSent, setIsOtpSent] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("api/profile", {
          headers: {
            authorisation: JSON.parse(localStorage.getItem("data")).token,
          },
        })
        setUser(response.data)

        // Assuming the backend sends 'mobileVerified' field
        setIsOtpSent(response.data.mobileVerified)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    if (!user.mobileVerified) {
      // Display an error or notification indicating that the mobile number needs to be verified.
      console.error("Mobile number must be verified before saving the profile.")
      return
    }

    const updatedFields = {
      username: user.username,
      contact: user.contact,
    }

    console.log("Updated Fields:", updatedFields)

    axios
      .put("api/profile", updatedFields, {
        headers: {
          authorisation: JSON.parse(localStorage.getItem("data")).token,
        },
      })
      .then((response) => {
        console.log("Response from backend:", response.data)
        if (response.status === 200) {
          setUser(response.data)
          setIsEditing(false)
        } else {
          console.error("Error:", response.data.error)
        }
      })
      .catch((error) => {
        console.error("Error:", error)
      })
  }

  const handleCancel = () => {
    setIsEditing(false)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (name === "contact") {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: `${value}`,
      }))
    } else {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }))
    }
  }

  const handleSendOtp = async () => {
    try {
      const response = await axios.post(
        "api/auth/sendotp-phone",
        {
          contact: `+${user.contact}`,
        },
        {
          headers: {
            authorisation: JSON.parse(localStorage.getItem("data")).token,
          },
        }
      )
      console.log("OTP sent successfully.", response.data)
      setIsOtpSent(true)
    } catch (error) {
      console.error("Error sending OTP:", error)
    }
  }

  const handleVerifyOtp = () => {
    axios
      .post(
        "api/auth/verifyotp-phone",
        { enteredOtp: otp },
        {
          headers: {
            authorisation: JSON.parse(localStorage.getItem("data")).token,
          },
        }
      )
      .then((response) => {
        console.log("OTP verified successfully.", response.data)
        setIsOtpSent(false)
        setUser((prevUser) => ({
          ...prevUser,
          mobileVerified: true,
        }))
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error)
      })
  }

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Profile Information</h2>
      <Container className="min-h-screen flex items-center justify-center">
        <Grid
          item
          lg={8}
        >
          <Card className="bg-gray-100 mb-4">
            <CardContent>
              <Grid
                container
                className="space-y-4"
              >
                {/* Full Name */}
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <Typography variant="body1">Full Name</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                >
                  {isEditing ? (
                    <TextField
                      name="username"
                      value={user.username}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >
                      {user.username}
                    </Typography>
                  )}
                </Grid>
                <hr className="w-full mt-4 mb-2" />

                {/* Email */}
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <Typography variant="body1">Email</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    {user.email}
                  </Typography>
                </Grid>
                <hr className="w-full mt-4 mb-2" />

                {/* Mobile */}
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <Typography variant="body1">Mobile</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                >
                  {isEditing ? (
                    <div>
                      <div className="">
                        {user.mobileVerified ? (
                          <>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                            >
                              +{user.contact} Verified
                              {<CheckCircleIcon sx={{ color: "green" }} />}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <PhoneInput
                              country={"in"}
                              value={user.contact}
                              onChange={(value) =>
                                handleInputChange({
                                  target: { name: "contact", value },
                                })
                              }
                              inputProps={{
                                required: true,
                              }}
                            />
                            {!isOtpSent ? (
                              <Button
                                onClick={handleSendOtp}
                                disabled={isOtpSent}
                              >
                                Send OTP
                              </Button>
                            ) : null}
                          </>
                        )}
                      </div>
                      {isOtpSent && !user.mobileVerified && (
                        <>
                          <TextField
                            sx={{ marginTop: "10px" }}
                            variant="outlined"
                            size="small"
                            label="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                          <Button
                            sx={{ marginTop: "10px" }}
                            onClick={handleVerifyOtp}
                          >
                            Verify OTP
                          </Button>
                        </>
                      )}
                    </div>
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >
                      +{user.contact}
                    </Typography>
                  )}
                </Grid>
                <hr className="w-full mt-4 mb-2" />

                {/* City */}
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <Typography variant="body1">City</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    {user.address && user.address.city
                      ? user.address.city
                      : "City not available"}
                  </Typography>
                </Grid>
                <hr className="w-full mt-4 mb-2" />

                {/* State */}
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <Typography variant="body1">State</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    {user.address && user.address.state
                      ? user.address.state
                      : "State not available"}
                  </Typography>
                </Grid>
                <hr className="w-full mt-4 mb-2" />

                {/* Edit and Save buttons */}
                <Grid
                  item
                  xs={12}
                  sm={3}
                ></Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                >
                  {isEditing ? (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleSave}
                        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none"
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleCancel}
                        className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none"
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleEdit}
                      className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none"
                    >
                      Edit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </div>
  )
}

export default MyProfile
