import React, { useState, useEffect } from "react"
import { Box } from "@mui/material"
import ProductCard from "../Components/ProductCard"
import Axios from "axios"

const Products = () => {
  const [products, setProducts] = useState([])

  useEffect(() => {
    Axios.get("api/products")
      .then((response) => {
        setProducts(response.data)
      })
      .catch((error) => {
        console.error("Error fetching products:", error)
      })
  }, [])
  const normalProducts = products.filter(
    (product) => !product.isSubscription && product.isStock
  )

  return (
    <Box
      display="flex"
      gap={3}
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
      py={3}
    >
      {normalProducts?.map((product) => (
        <ProductCard
          key={product?._id}
          name={product?.name}
          price={product?.price}
          originalprice={product?.originalPrice}
          image={product?.images?.length > 0 ? product.images[0]?.url : null}
          productId={product?._id}
          averageRating={product?.averageRating}
        />
      ))}
    </Box>
  )
}

export default Products
