import { Outlet } from "react-router-dom"

const SignUp = () => {
  return (
    <div className="content">
      <Outlet />
      <img
        className="bird"
        src="/ss.png"
        alt=""
        srcSet=""
      />
    </div>
  )
}

export default SignUp
