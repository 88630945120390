import React, { createContext, useReducer, useEffect } from "react"
import Axios from "axios"

export const CartContext = createContext()

const cartReducer = (state, action) => {
  switch (action.type) {
    case "SET_CART":
      return {
        ...state,
        cartItems: Array.isArray(action.payload) ? action.payload : [],
        cartCount: Array.isArray(action.payload) ? action.payload.length : 0,
      }
    case "UPDATE_QUANTITY":
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item._id === action.payload.cartItemId
            ? { ...item, quantity: action.payload.quantity }
            : item
        ),
      }
    case "REMOVE_ITEM":
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) => item._id !== action.payload.cartItemId
        ),
        cartCount: state.cartCount - 1, // Decrement count when removing an item
      }
    case "SET_CART_COUNT":
      return {
        ...state,
        cartCount: action.payload,
      }
    default:
      return state
  }
}

export function CartProvider({ children }) {
  const [state, dispatch] = useReducer(cartReducer, {
    cartItems: [],
    cartCount: 0,
  })

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart"))
    if (storedCart) {
      dispatch({ type: "SET_CART", payload: storedCart })
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(state.cartItems))
  }, [state.cartItems])

  // Fetch cart data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token
        const response = await Axios.get("api/getCart", {
          headers: {
            authorisation: token,
          },
        })
        dispatch({ type: "SET_CART", payload: response.data })
      } catch (error) {
        console.error("Error fetching cart:", error)
      }
    }

    fetchData()
  }, [])

  // Update cart count based on cart items
  useEffect(() => {
    dispatch({ type: "SET_CART_COUNT", payload: state.cartItems.length })
  }, [state.cartItems])

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  )
}
