import React from "react"
import Form from "./Form"
import Sidebar from "./Sidebar"

const Main = () => {
  return (
    <div className="child">
      <main>
        <Sidebar />
        <Form />
      </main>
    </div>
  )
}

export default Main
