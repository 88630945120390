import React from "react"
import { FaCheck, FaTimes } from "react-icons/fa"

const Compare = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-10 ">
      <h1 className="mb-4 text-center text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold leading-none tracking-tight text-gray-900 max-w-2xl sm:max-w-3xl md:max-w-4xl lg:max-w-5xl xl:max-w-6xl">
        An enhanced care system for parents and their little ones.
      </h1>
      <p className="mb-6 text-base md:text-lg lg:text-xl xl:text-2xl font-normal text-gray-900 sm:px-4 md:px-8 xl:px-16">
        Compare our services with traditional providers.
      </p>

      <div className=" sm:rounded-lg p-2">
        <table className="w-full md:w-5/6 text-sm  mx-auto text-gray-500  md:table-fixed">
          <thead className="text-xs text-gray-700  ">
            <tr className="border-b border-gray-200">
              <th
                scope="col"
                className="w-1/2 px-4 py-3 "
              ></th>
              <th
                scope="col"
                className="w-1/5 px-4 py-3  bg-[#77DD77] rounded-t-lg text-lg"
              >
                Simba Speaks
              </th>
              <th
                scope="col"
                className="w-1/3 px-4 py-3 text-lg"
              >
                Traditional providers
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200 ">
              <th
                scope="row"
                className="px-6 py-4 text-md  text-[#5a5a5a] text-left sm:w-1/3 md:w-1/4 lg:w-1/5"
              >
                Therapist matched to your clinical needs
              </th>
              <td className="px-6 py-4 bg-[#77DD77]  sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaCheck className="text-black rounded-full m-1 p-1 bg-[#28A228] text-4xl inline-block" />
              </td>
              <td className="px-6 py-4 sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaTimes className="text-black rounded-full m-1 p-1 text-4xl bg-[#FF3D33] inline-block" />
              </td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 text-md text-[#5a5a5a] text-left "
              >
                Flexible scheduling (7 days/week + after school)
              </th>
              <td className="px-6 py-4 bg-[#77DD77]  sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaCheck className="text-black rounded-full m-1 p-1 bg-[#28A228] text-4xl inline-block" />
              </td>
              <td className="px-6 py-4 sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaTimes className="text-black rounded-full m-1 p-1 text-4xl bg-[#FF3D33] inline-block" />
              </td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 text-md text-[#5a5a5a] text-left "
              >
                Practice exercises after each session
              </th>
              <td className="px-6 py-4 bg-[#77DD77]  sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaCheck className="text-black rounded-full m-1 p-1 bg-[#28A228] text-4xl inline-block" />
              </td>
              <td className="px-6 py-4 sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaTimes className="text-black rounded-full m-1 p-1 text-4xl bg-[#FF3D33] inline-block" />
              </td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 text-md text-[#5a5a5a] text-left"
              >
                Weekly progress reports
              </th>
              <td className="px-6 py-4 bg-[#77DD77]  sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaCheck className="text-black rounded-full m-1 p-1 bg-[#28A228] text-4xl inline-block" />
              </td>
              <td className="px-6 py-4 sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaTimes className="text-black rounded-full m-1 p-1 text-4xl bg-[#FF3D33] inline-block" />
              </td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 text-md text-[#5a5a5a] text-left  "
              >
                Text your therapist anytime
              </th>
              <td className="px-6 py-4 bg-[#77DD77]  sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaCheck className="text-black rounded-full m-1 p-1 bg-[#28A228] text-4xl inline-block" />
              </td>
              <td className="px-6 py-4 sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaTimes className="text-black rounded-full m-1 p-1 text-4xl bg-[#FF3D33] inline-block" />
              </td>
            </tr>
            <tr className="border-b border-gray-200">
              <th
                scope="row"
                className="px-6 py-4 text-md text-[#5a5a5a] text-left "
              >
                Convenient patient portal
              </th>
              <td className="px-6 py-4 bg-[#77DD77]  sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaCheck className="text-black rounded-full m-1 p-1 bg-[#28A228] text-4xl inline-block" />
              </td>
              <td className="px-6 py-4 sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaTimes className="text-black rounded-full m-1 p-1 text-4xl bg-[#FF3D33] inline-block" />
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                className="px-6 py-4 text-md text-[#5a5a5a] text-left"
              >
                No commutes or waiting rooms
              </th>
              <td className="px-6 py-4 bg-[#77DD77] rounded-b-lg  sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaCheck className="text-black rounded-full m-1 p-1 bg-[#28A228] text-4xl inline-block" />
              </td>
              <td className="px-6 py-4 sm:w-1/3 md:w-1/4 lg:w-1/5 text-center">
                <FaTimes className="text-black rounded-full m-1 p-1 text-4xl bg-[#FF3D33] inline-block" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Compare
