import React, { useState, useContext } from "react"
import FormHeader from "./FormHeader"
import styles from "./PersonalInfo.module.css"
import Input from "../../../Helpers/Input"
import Button from "../../../Helpers/Button"
import Checkbox from "../../../Helpers/Checkbox"
import useMobile from "../../../Hooks/child/useMobile"
import { GlobalContext } from "../../../Hooks/child/GlobalContext"
import Dropdown from "../../../Helpers/Dropdown"
import { errorToast } from "../../../utils/toastConfig"
const PersonalInfo = () => {
  const questions = [
    {
      label: "Child Name",
      name: "name",
      inputType: "text",
      placeholder: "Enter Child's Name",
    },
    {
      label: "Gender of your child",
      name: "age",
      options: ["Male", "Female"],
    },
    {
      label: "Age of your child",
      name: "gender",
      options: ["2-3 years", "3-4 years", "4+ years"],
    },
  ]
  const { step, setStep, formData, setFormData } = useContext(GlobalContext)
  const mobile = useMobile("(max-width:940px)")
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [answers, setAnswers] = useState({})

  const handleChange = (name, value) => {
    setAnswers({ ...answers, [name]: value })
    const updatedValue = Array.isArray(value) ? value[0] : value
    setFormData({ ...formData, [name]: updatedValue })
  }

  const handleNext = () => {
    if (
      currentQuestion < questions.length - 1 &&
      !formData[questions[currentQuestion].name]
    ) {
      errorToast("Please answer the current question before proceeding.")
    } else {
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1)
      } else {
        if (!formData[questions[currentQuestion].name]) {
          errorToast("Please answer the last question before proceeding.")
        } else {
          setStep(step + 1)
          console.log(formData)
        }
      }
    }
  }

  // const handleBack = () => {
  //   if (currentQuestion > 0) {
  //     setCurrentQuestion(currentQuestion - 1)
  //   }
  // }

  return (
    <>
      <FormHeader
        title="Child Info"
        info="Please provide your name, age, gender."
      />
      <form
        className={mobile ? `${styles.form} ${styles.mobile}` : styles.form}
        onSubmit={(e) => {
          e.preventDefault()
          handleNext()
        }}
      >
        {currentQuestion < questions.length && (
          <>
            {questions[currentQuestion].inputType ? (
              <Input
                type={questions[currentQuestion].inputType}
                id={questions[currentQuestion].name}
                label={questions[currentQuestion].label}
                placeholder={questions[currentQuestion].placeholder}
                value={formData[questions[currentQuestion].name]}
                onChange={(e) =>
                  handleChange(questions[currentQuestion].name, e.target.value)
                }
              />
            ) : questions[currentQuestion].name === "initialComplaint" ? (
              <Dropdown
                label={questions[currentQuestion].label}
                options={questions[currentQuestion].options.map((option) => ({
                  name: option,
                  value: option,
                }))}
                value={answers[questions[currentQuestion].name] || ""}
                setValue={(value) =>
                  handleChange(questions[currentQuestion].name, value)
                }
              />
            ) : (
              <Checkbox
                label={questions[currentQuestion].label}
                options={questions[currentQuestion].options.map((option) => ({
                  name: option,
                  value: option,
                }))}
                value={answers[questions[currentQuestion].name] || []}
                setValue={(value) =>
                  handleChange(questions[currentQuestion].name, value)
                }
              />
            )}
          </>
        )}

        <section className="flex mt-2 justify-between rounded-md w-full">
          {/* {currentQuestion > 0 && (
            <Button
              variant="ghost"
              id="back"
              buttonInfo="Back"
              onClick={handleBack}
            />
          )} */}
          {currentQuestion < questions.length && (
            <Button
              id="next"
              buttonInfo={
                currentQuestion === questions.length - 1
                  ? "Next Step"
                  : "Next Question"
              }
              type="submit"
            />
          )}
        </section>
      </form>
    </>
  )
}

export default PersonalInfo
