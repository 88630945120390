const questionsByAge = {
  1: [
    {
      question: "Does your child participate in social games like peek-a-boo?",
      options: ["Always", "Sometimes", "Never"],
      description:
        "Observe if the child responds with a smile during interactions.",
    },
    {
      question:
        "Does your child understand or follow commands? (come here, give me)",
      options: ["Always", "Sometimes", "Never"],
      description: "Observe how the child responds to simple commands.",
    },
    {
      question:
        "Does your child imitate non-speech (vrooom! vrooom!!, mooooo!!)",
      options: ["Always", "Sometimes", "Never"],
      description:
        "Observe if the child imitates non-speech sounds during play.",
    },
    {
      question: "Does your child use gestures/actions to communicate?",
      options: ["Yes", "No"],
      description:
        "Observe if the child uses gestures or actions to convey messages.",
    },
    {
      question: "Can your child say 1 word regularly & spontaneously?",
      options: ["Yes", "No"],
      description: "Observe if the child spontaneously says single words.",
    },
  ],
  2: [
    {
      question: "Does your child maintain eye contact during interactions?",
      options: ["Yes", "No"],
      description:
        "Observe if the child maintains eye contact while interacting.",
    },
    {
      question: "Does your child respond to their name?",
      options: ["Always", "Sometimes", "Never"],
      description: "Observe how the child responds when their name is called.",
    },
    {
      question:
        "Does your child follow simple commands that include items? (Eg- Give me the ball?)",
      options: ["Always", "Sometimes", "Never"],
      description:
        "Observe if the child follows simple commands involving items.",
    },
    {
      question:
        "Does your child make at least 5 consonant sounds? (p, b, m, t, d, n, w, h)",
      options: ["Yes", "No"],
      description:
        "Observe if the child produces at least 5 different consonant sounds.",
    },
    {
      question:
        "Does your child use more than 25 words regularly & spontaneously?",
      options: ["Yes", "No"],
      description:
        "Observe if the child uses a vocabulary of more than 25 words.",
    },
  ],
  3: [
    {
      question: "Does your child follow 2-step directions?",
      options: ["Always", "Sometimes", "Never"],
      description:
        "Observe if the child can follow instructions with two steps.",
    },
    {
      question:
        "Does your child understand - what, where, when & why questions?",
      options: ["Always", "Sometimes", "Never"],
      description:
        "Observe if the child comprehends questions related to what, where, when, and why.",
    },
    {
      question: "Does your child love playing and talking with other children?",
      options: ["Yes", "No"],
      description:
        "Observe if the child enjoys playing and conversing with other children.",
    },
    {
      question:
        "Does your child make all the consonant sounds? (p, b, m, t, d, n, w, h, k, g, f, y, etc…)",
      options: ["Yes", "No"],
      description:
        "Observe if the child can produce a wide range of consonant sounds.",
    },
    {
      question: "Does your child use 3-4 word sentences?",
      options: ["Yes", "No"],
      description: "Observe if the child forms sentences with 3-4 words.",
    },
  ],
  4: [
    {
      question:
        "Does your child understand time & sequencing concepts? (Eg - today/tomorrow and first/next/last)",
      options: ["Yes", "No"],
      description:
        "Observe if the child grasps concepts related to time and sequencing.",
    },
    {
      question: "Does your child show the ability to rhyme?",
      options: ["Yes", "No"],
      description:
        "Observe if the child demonstrates the ability to rhyme words.",
    },
    {
      question: "Does your child tell his/her imaginations?",
      options: ["Yes", "No"],
      description: "Observe if the child shares their imaginative thoughts.",
    },
    {
      question:
        "Do you and other listeners understand 50% to 75% of what your child says?",
      options: ["Yes", "No"],
      description:
        "Observe if you and others can understand 50% to 75% of the child's speech.",
    },
    {
      question: "Does your child speak without stammering?",
      options: ["Always", "Sometimes", "Never"],
      description: "Observe if the child speaks fluently without stammering.",
    },
  ],
  5: [
    {
      question:
        "Does your child pronounce all speech sounds/words correctly with few to no errors?",
      options: ["Always", "Sometimes", "Never"],
      description:
        "Observe if the child grasps concepts related to time and sequencing.",
    },
    {
      question: "Does your child use correct grammar?",
      options: ["Yes", "No"],
      description:
        "Observe if the child demonstrates the ability to rhyme words.",
    },
    {
      question:
        "Can your child have a focused back-and-forth conversation with both children and adults?",
      options: ["Yes", "No"],
      description: "Observe if the child shares their imaginative thoughts.",
    },
    {
      question:
        "Do your child tell a story or answer questions about a story read to them?",
      options: ["Yes", "No"],
      description:
        "Observe if you and others can understand 50% to 75% of the child's speech.",
    },
    {
      question: "Can your child speak without stammering?",
      options: ["Always", "Sometimes", "Never"],
      description: "Observe if the child speaks fluently without stammering.",
    },
  ],
}

export default questionsByAge
