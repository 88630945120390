import React from "react"
import { Typography, Card, CardContent, Container } from "@mui/material"

const ComingSoon = () => {
  return (
    <Container
      maxWidth="sm"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "65vh",
      }}
    >
      <Card
        style={{
          backgroundColor: "#FBEFEF",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent style={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            gutterBottom
          >
            Coming Soon!
          </Typography>
          <Typography
            variant="body1"
            paragraph
          >
            Exciting things are on the way. Stay tuned for updates!
          </Typography>
        </CardContent>
      </Card>
    </Container>
  )
}

export default ComingSoon
