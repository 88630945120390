import {Box, Typography} from '@mui/material'

const ContactUs = () => {
    return ( 
        <Box display="flex" flexDirection="column" gap={2} sx={{width:{xs:"80vw", sm:"40vw"}}}>
            <Typography variant="h5" fontWeight={600}>Contact Us</Typography>
            <Typography variant="p" color="#6A788C" fontWeight={500}>Last updated on Oct 26th 2023</Typography>
            <Typography variant="p" color="#6A788C" fontSize="15px">You may contact us using the information below:</Typography>
            <Typography variant="p" color="#6A788C" fontSize="15px">Merchant Legal entity name: Simba Speaks</Typography>
            <Typography variant="p" color="#6A788C" fontSize="15px">Registered Address: B2F2B , Moon City , Rajiv Path , Dimna Road , Mango Jamshedpur JHARKHAND 831012</Typography>
            <Typography variant="p" color="#6A788C" fontSize="15px">Telephone No: 9304026228</Typography>
            <Typography variant="p" color="#6A788C" fontSize="15px">E-Mail ID: support@simbaspeaks.in</Typography>
        </Box>
     );
}
 
export default ContactUs;