import { Box } from "@mui/material";
import PolicySection from "../Components/Policy/PolicySection";
import { Outlet } from "react-router-dom";

const Policy = () => {
    return ( 
        <Box mt={5} pb={3} display="flex" justifyContent="center" sx={{flexDirection:{xs:"column", sm:"row"}, alignItems:{xs:"center", sm:"start"}}}>
            <PolicySection/>
            <Outlet/>
        </Box>
     );
}
 
export default Policy;