import {
  Box,
  Typography,
  Stack,
  InputLabel,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { useState } from "react"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { useNavigate, useLocation } from "react-router-dom"
import axios from "axios"
import { successToast, errorToast } from "../utils/toastConfig"

const NewPassCard = () => {
  const [pwd, setPwd] = useState("")
  const [confirmPwd, setConfirmPwd] = useState("")
  const [values, setValues] = useState({
    showPassword: false,
  })
  const [message, setMessage] = useState("") // To display success or error messages
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const email = queryParams.get("email")
  //password visibility
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleResetPassword = () => {
    if (!pwd) {
      errorToast("Please enter a new password.")
      setMessage("Please enter a new password.")
    } else if (pwd !== confirmPwd) {
      errorToast("Passwords do not match.")
      setMessage("Passwords do not match.")
    } else {
      axios
        .put("api/auth/resetPassword", {
          email: email,
          newPassword: pwd,
        })
        .then((response) => {
          setMessage(response.data.message)
          if (response.data.success) {
            successToast("Password reset successful!")
            navigate("/")
          } else {
            errorToast("Password reset failed. Please try again.")
          }
        })
        .catch((error) => {
          setMessage("An error occurred. Please try again.")
          errorToast("An unexpected error occurred. Please try again later.")
        })
    }
  }

  return (
    <Box
      height="max-content"
      ml={{ xs: 0, md: 30 }}
      padding="20px 0px"
      bgcolor="white"
      width={{ xs: "100vw", md: "25vw" }}
      border={{ xs: 0, md: "1px solid #999999" }}
      borderRadius={2}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Box
          display="flex"
          justifyContent="start"
          width="70%"
        >
          <IconButton
            aria-label="back-button"
            onClick={() => {
              navigate(-1)
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Box>
        <img
          className="logo_tagline"
          src="/logo_tagline.png"
          alt=""
          srcSet=""
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h3"
            fontWeight={700}
            fontSize="25px"
          >
            Reset Password
          </Typography>
          <Typography
            variant="p"
            color="#3d3d3d"
            fontSize="9px"
          >
            Password must be at least 8 characters long. <br />
            Password must contain at least one upper case. <br />
            One lower case letter. <br />
            Password must contain at least one number or special character
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="start"
          flexDirection="column"
          gap={2}
        >
          <Stack sx={{ marginTop: "10px", alignItems: "start" }}>
            <InputLabel htmlFor="outlined-adornment-password">
              New Password
            </InputLabel>
            <OutlinedInput
              id="pwd"
              placeholder="Enter your password"
              type={values.showPassword ? "text" : "password"}
              value={pwd}
              size="small"
              onChange={(e) => {
                setPwd(e.target.value)
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </Stack>
          <Stack sx={{ marginTop: "10px", alignItems: "start" }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="pwd"
              placeholder="Re-enter your password"
              type={values.showPassword ? "text" : "password"}
              value={confirmPwd}
              size="small"
              onChange={(e) => {
                setConfirmPwd(e.target.value)
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
          </Stack>
          <p>{message}</p>
          <Button
            variant="contained"
            fullWidth
            onClick={handleResetPassword}
          >
            Proceed
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}

export default NewPassCard
