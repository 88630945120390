import React, { useState, useContext } from "react"
import FormHeader from "./FormHeader"
import styles from "./PersonalInfo.module.css"
import Input from "../../../Helpers/Input"
import Button from "../../../Helpers/Button"
import Checkbox from "../../../Helpers/Checkbox"
import useMobile from "../../../Hooks/child/useMobile"
import { GlobalContext } from "../../../Hooks/child/GlobalContext"
import Dropdown from "../../../Helpers/Dropdown"
// import questions from "../formQuestions"
import { successToast, errorToast } from "../../../utils/toastConfig"
import axios from "axios"

const InitialComplaint = () => {
  const questions = [
    {
      label: "Initial Complaint",
      name: "initialComplaint",
      options: [
        "Speech Delay",
        "Language Disorder",
        "Early Language Developement",
        "Autism Spectrum Disorder",
        "Stuttering",
        "Lisp",
      ],
    },
  ]
  const { step, setStep, formData, setFormData } = useContext(GlobalContext)
  const mobile = useMobile("(max-width:940px)")
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [answers, setAnswers] = useState({})

  const handleChange = (name, value) => {
    setAnswers({ ...answers, [name]: value })
    const updatedValue = Array.isArray(value) ? value[0] : value
    setFormData({ ...formData, [name]: updatedValue })
  }

  //   const handleNext = () => {
  //     if (
  //       currentQuestion < questions.length - 1 &&
  //       !formData[questions[currentQuestion].name]
  //     ) {
  //       errorToast("Please answer the current question before proceeding.")
  //     } else {
  //       if (currentQuestion < questions.length - 1) {
  //         setCurrentQuestion(currentQuestion + 1)
  //       } else {
  //         if (!formData[questions[currentQuestion].name]) {
  //           errorToast("Please answer the last question before proceeding.")
  //         } else {
  //           setStep(step + 1)
  //           console.log(formData)
  //         }
  //       }
  //     }
  //   }
  const handleNext = async () => {
    if (
      currentQuestion < questions.length - 1 &&
      !formData[questions[currentQuestion].name]
    ) {
      errorToast("Please answer the current question before proceeding.")
    } else {
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1)
      } else {
        if (!formData[questions[currentQuestion].name]) {
          errorToast("Please answer the last question before proceeding.")
        } else {
          try {
            const response = await axios.post(
              "api/add_child",
              formData, // Send formData directly to the backend
              {
                headers: {
                  authorisation: JSON.parse(localStorage.getItem("data")).token,
                },
              }
            )

            if (response.status === 201) {
              successToast("Child added successfully!")
              const data = response.data
              successToast(data)
              setStep(step + 1)
            } else {
              console.error("Error:", response.data.error.message)
              errorToast("Failed to add child. Please try again.")
            }
          } catch (error) {
            console.error("Error:", error.message)
            errorToast("An unexpected error occurred. Please try again.")
          }
        }
      }
    }
  }

  //   const handleBack = () => {
  //     if (currentQuestion > 0) {
  //       setCurrentQuestion(currentQuestion - 1)
  //     }
  //   }

  return (
    <>
      <FormHeader
        title="Initial Complaint"
        info="Please select the Initial Complaint."
      />
      <form
        className={mobile ? `${styles.form} ${styles.mobile}` : styles.form}
        onSubmit={(e) => {
          e.preventDefault()
          handleNext()
        }}
      >
        {currentQuestion < questions.length && (
          <>
            {questions[currentQuestion].inputType ? (
              <Input
                type={questions[currentQuestion].inputType}
                id={questions[currentQuestion].name}
                label={questions[currentQuestion].label}
                placeholder={questions[currentQuestion].placeholder}
                value={formData[questions[currentQuestion].name]}
                onChange={(e) =>
                  handleChange(questions[currentQuestion].name, e.target.value)
                }
              />
            ) : questions[currentQuestion].name === "initialComplaint" ? (
              <Dropdown
                label={questions[currentQuestion].label}
                options={questions[currentQuestion].options.map((option) => ({
                  name: option,
                  value: option,
                }))}
                value={answers[questions[currentQuestion].name] || ""}
                setValue={(value) =>
                  handleChange(questions[currentQuestion].name, value)
                }
              />
            ) : (
              <Checkbox
                label={questions[currentQuestion].label}
                options={questions[currentQuestion].options.map((option) => ({
                  name: option,
                  value: option,
                }))}
                value={answers[questions[currentQuestion].name] || []}
                setValue={(value) =>
                  handleChange(questions[currentQuestion].name, value)
                }
              />
            )}
          </>
        )}

        <section className="flex mt-2 justify-between rounded-md w-full">
          {/* {currentQuestion > 0 && (
            <Button
              variant="ghost"
              id="back"
              buttonInfo="Back"
              onClick={handleBack}
            />
          )} */}
          {currentQuestion < questions.length && (
            <Button
              id="next"
              buttonInfo={
                currentQuestion === questions.length - 1
                  ? "Next Step"
                  : "Next Question"
              }
              type="submit"
            />
          )}
        </section>
      </form>
    </>
  )
}

export default InitialComplaint
