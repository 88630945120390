import React, { useState } from "react"
import "./blog.css" // Import the CSS file

const Blog = () => {
  const postsPerPage = 6
  const [currentPage, setCurrentPage] = useState(1)

  // Example blog posts data
  const blogPosts = Array.from({ length: 20 }, (_, index) => ({
    id: index + 1,
    title: `Blog Post ${index + 1}`,
    description:
      "Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.",
    image: "https://www.simbaspeaks.in/program-kids.png",
  }))

  const totalPages = Math.ceil(blogPosts.length / postsPerPage)

  // Get current posts
  const currentPosts = blogPosts.slice(
    (currentPage - 1) * postsPerPage,
    currentPage * postsPerPage
  )

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {currentPosts.map((post) => (
          <div key={post.id}>
            <div className="blog-card max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <a href="#">
                <img
                  className="blog-image rounded-t-lg"
                  src={post.image}
                  alt=""
                />
              </a>
              <div className="p-3 blog-content">
                <a href="#">
                  <h5 className="mb-1 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {post.title}
                  </h5>
                </a>
                <p className="mb-2 text-sm font-normal text-gray-700 dark:text-gray-400">
                  {post.description}
                </p>
              </div>
              <a
                href="#"
                className="read-more-button inline-flex items-center px-2 py-1 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Read more
                <svg
                  className="rtl:rotate-180 w-3 h-3 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="pagination mt-6">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handleClick(index + 1)}
            className={`px-3 py-1 mx-1 ${
              currentPage === index + 1
                ? "bg-blue-700 text-white"
                : "bg-gray-200 text-gray-700"
            } rounded-lg`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  )
}

export default Blog
