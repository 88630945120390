import React from "react"
import Footer from "./Footer"

const FeatureCard = ({ title, description }) => {
  return (
    <div className="flex flex-col w-full mx-auto rounded-dave gap-3 rounded-xl bg-gray-100 text-dave-forest overflow-hidden transition-all duration-350 p-10 md:p-12 justify-center items-center text-center bg-dave-lightgray md:basis-[calc(33.33%-1.67rem)]">
      <div className="mb-7 md:mb-5"></div>
      <h5 className="text-xl md:text-2xl font-bold  m-0">{title}</h5>
      <div className="font-dmsans">
        <p>{description}</p>
      </div>
    </div>
  )
}

const About = () => {
  return (
    <>
      <div className="mx-auto max-w-screen-xl px-6 py-8 ">
        {/* First content section */}
        <div className="flex flex-col px-10 md:px-24 py-10 text-left md:py-20 md:text-center text-gray-800 bg-[#f2f8e2] rounded-lg">
          <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-extrabold tracking-wide text-[#004318]">
            Let your child ROAR with CONFIDENCE!
          </h1>

          <p class="text-3xl md:text-4xl mt-2  font-semibold tracking-wide text-[#004318]">
            Welcome to Simba Speaks
          </p>
        </div>
        <div className="md:h-10 h-4"></div>
        {/* First image section */}
        <div className="flex flex-wrap gap-4 md:grid md:grid-cols-2 md:gap-10 ">
          <div className="aspect-w-3 aspect-h-2 md:aspect-auto md:object-cover ">
            <div
              style={{
                maxWidth: "698px",
                display: "block",
                position: "relative",
              }}
            >
              <img
                alt=""
                role="presentation"
                aria-hidden="true"
                src="/about1.jpg"
                className="rounded-xl"
                style={{
                  maxWidth: "100%",
                  display: "block",
                  position: "static",
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-6 p-6 md:p-10 bg-[#004318] justify-end rounded-xl">
            <div className="md:flex md:flex-col md:gap-y-6">
              <h3 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold tracking-wide text-white ">
                Our Mission:
              </h3>
              <div className="text-lg md:text-xl text-white font-['DM_Sans']">
                <p>
                  To revolutionize the way speech therapy is approached by
                  promoting a hybrid model that integrates clinical expertise
                  with home-based interventions. We recognize the invaluable
                  role parents play in their child's development and provide
                  them with the tools, guidance, and support they need to foster
                  effective communication skills in their little ones.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="md:h-20 h-8"></div>
        {/* First image section */}
        <div className="flex flex-wrap gap-4 md:grid md:grid-cols-2 md:gap-10">
          <div className="flex flex-col gap-y-6 p-6 md:p-10 bg-[#008849] justify-end rounded-xl">
            <div className="md:flex md:flex-col md:gap-y-6">
              <h3 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold tracking-wide text-white ">
                What Sets Us Apart:
              </h3>
              <div className="text-lg md:text-xl text-white">
                <p>
                  At Simba Speaks, we believe in empowering parents to become
                  the heroes in their child's speech development journey. Our
                  innovative approach combines professional expertise with
                  parental involvement, creating a dynamic ecosystem for early
                  intervention in children's speech and language development.
                </p>
              </div>
            </div>
          </div>
          <div className="aspect-w-3 aspect-h-2 md:aspect-auto md:object-cover">
            <div
              style={{
                maxWidth: "698px",
                display: "block",
                position: "relative",
              }}
            >
              <img
                alt=""
                role="presentation"
                aria-hidden="true"
                src="/about2.jpg"
                className="rounded-xl"
                style={{
                  maxWidth: "100%",
                  display: "block",
                  position: "static",
                }}
              />
            </div>
          </div>
        </div>
        <div className="md:h-20 h-8"></div>

        <div className="flex flex-col px-8 md:px-16 py-8 md:py-12 text-center gap-5  rounded-xl">
          <h2 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold tracking-wide text-[#004318]">
            Listening and Learning
          </h2>
          <p className="text-lg font-semibold leading-relaxed">
            We're dedicated to our community and are always listening to learn
            how we can improve and better serve you.
          </p>
        </div>

        <div className="md:h-20 h-8"></div>
        {/* Third image section */}
        <div className="flex flex-wrap gap-4 md:grid md:grid-cols-2 md:gap-10">
          <div className="flex flex-col gap-y-6 p-6 md:p-10 bg-[#004318] justify-end rounded-xl">
            <div className="md:flex md:flex-col md:gap-y-6">
              <h3 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-extrabold tracking-wide text-white ">
                Our Programs
              </h3>
              <div className="text-lg md:text-xl text-white">
                <p>
                  Hanen-based Home Program: Designed for intensive at-home
                  therapy, this program empowers parents to become proficient in
                  speech therapy techniques, supported by detailed plans and
                  regular progress tracking.
                </p>
              </div>
            </div>
          </div>
          <div className="aspect-w-3 aspect-h-2 md:aspect-auto md:object-cover">
            <div
              style={{
                maxWidth: "698px",
                display: "block",
                position: "relative",
              }}
            >
              <img
                alt=""
                role="presentation"
                aria-hidden="true"
                src="/about3.jpg"
                className="rounded-xl"
                style={{
                  maxWidth: "100%",
                  display: "block",
                  position: "static",
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-4 my-8">
          <FeatureCard
            title="Comprehensive Services"
            description="From virtual consultations to detailed assessments, we offer a range of services tailored to meet your child's unique needs."
          />
          <FeatureCard
            title="Professional Guidance"
            description="Our team of licensed and experienced therapists work hand-in-hand with parents to create personalized therapy plans and provide ongoing support."
          />
          <FeatureCard
            title="Holistic Approach"
            description=" We believe in fostering speech and language development in natural, everyday settings, incorporating play-based activities and real-life interactions into our programs."
          />
          <FeatureCard
            title="Hanen Approach"
            description="Our Hanen-based program equips parents with the skills and confidence to implement speech therapy techniques at home, reducing dependency on clinic-based sessions."
          />
          <FeatureCard
            title="Transparent Pricing"
            description="With transparent and affordable pricing, we ensure accessibility to quality speech therapy services for all families."
          />
          <FeatureCard
            title="Our Programs"
            description="Guidance Program: For parents seeking basic guidance and support, our one-on-one sessions provide essential tools and strategies to kickstart their child's speech journey."
          />
        </div>

        {/* <div className="flex flex-wrap gap-4 md:grid md:grid-cols-2 md:gap-10">
          <div className="flex flex-col gap-y-6 rounded-lg p-10 md:p-20 bg-[#004318] md:justify-end order-last">
            <div className="contents md:flex md:flex-col md:gap-y-6">
              <h3 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-extrabold tracking-wide text-white">
                Fighting for the underdog
              </h3>
              <div className="text-base md:text-lg text-white">
                <p>
                  We started Dave for one reason: banks weren’t built for people
                  like us, and we knew we deserved better. Like David slaying
                  Goliath, we’re taking on big banks and their predatory ways.
                </p>
              </div>
            </div>
          </div>
          <div className="aspect-w-3 aspect-h-2 md:aspect-auto md:object-cover">
            <div
              style={{
                maxWidth: "698px",
                display: "block",
                position: "relative",
              }}
            >
              <img
                alt=""
                role="presentation"
                aria-hidden="true"
                src="https://images.ctfassets.net/rkv150f3eozw/18E8O0XQl3qO3TSMswRMdE/bdf2e2432a8cccb0f98d5a119860217a/About_Child__1_.png?w=698&h=700&q=100&fm=webp&bg=transparent"
                style={{
                  maxWidth: "100%",
                  display: "block",
                  position: "static",
                }}
              />
            </div>
          </div>
        </div> */}
        <div className="md:h-20 h-8"></div>
      </div>
      <Footer />
    </>
  )
}

export default About
