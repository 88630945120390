import React from "react"
import { Typography, Button } from "@mui/material"
import { Link } from "react-router-dom"

const EmptyCart = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "5rem" }}>
      <Typography
        variant="h4"
        gutterBottom
      >
        Your Cart is Empty
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
      >
        Add items to your cart to continue shopping.
      </Typography>
      <Link
        to="/products"
        style={{ textDecorationLine: "none" }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
        >
          Shop Now
        </Button>
      </Link>
    </div>
  )
}

export default EmptyCart
