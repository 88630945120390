import React from "react"
import styles from "./Checkbox.module.css"

const Checkbox = ({ options, value, setValue, label }) => {
  const handleChange = (event) => {
    const selectedValue = event.target.value
    const isChecked = event.target.checked
    // if (isChecked) {
    //   setValue([...value, selectedValue])
    // } else {
    //   setValue(value.filter((val) => val !== selectedValue))
    // }
    if (isChecked) {
      setValue([selectedValue])
    } else {
      setValue([])
    }
  }

  return (
    <div>
      <label>{label}</label>
      {options.map((option, index) => (
        <label
          key={index}
          htmlFor={option.value} // Use value as the unique key
          className={
            value.includes(option.value)
              ? `${styles.checkboxWrapper} ${styles.active}`
              : styles.checkboxWrapper
          }
        >
          <input
            id={option.value} // Use value as the id
            type="checkbox"
            value={option.value} // Use value as the value
            checked={value.includes(option.value)}
            onChange={handleChange}
          />
          <div>
            <p>{option.name}</p> {/* Use name as the label */}
          </div>
        </label>
      ))}
    </div>
  )
}

export default Checkbox
