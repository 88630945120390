import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import axios from "axios"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import "./carousel.css"

const PreviousBtn = (props) => {
  const { className, onClick } = props
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <ArrowBackIos style={{ color: "blue", fontSize: "30px" }} />
    </div>
  )
}

const NextBtn = (props) => {
  const { className, onClick } = props
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <ArrowForwardIos style={{ color: "blue", fontSize: "30px" }} />
    </div>
  )
}

const Carousel = () => {
  const [carouselData, setCarouselData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    axios
      .get("api/carousel")
      .then((response) => setCarouselData(response?.data))
      .catch((error) => console.error("Error fetching carousel data:", error))
  }, [])

  if (carouselData?.length === 0) {
    return null
  }

  const handleCarouselItemClick = (link) => {
    navigate(link)
  }

  const getResponsiveImageUrl = (item) => {
    const screenWidth = window.innerWidth

    if (item?.images) {
      if (screenWidth >= 1200) {
        return item?.images?.bigScreen
      } else if (screenWidth >= 600) {
        return item?.images.tablet
      } else {
        return item?.images.phone
      }
    }

    return ""
  }

  return (
    <div
      style={{ margin: "10px", cursor: "pointer" }}
      className="carousel"
    >
      <Slider
        autoplay
        autoplaySpeed={5000}
        initialSlide={2}
        infinite
        prevArrow={<PreviousBtn />}
        nextArrow={<NextBtn />}
        // customPaging={(i) => (
        //   <div key={i}>
        //     <img
        //       src={getResponsiveImageUrl(carouselData[i]?.images)}
        //       alt=""
        //       style={{
        //         width: "50px",
        //         height: "50px",
        //         objectFit: "cover",
        //         borderRadius: "10px",
        //       }}
        //     />
        //   </div>
        // )}
      >
        {carouselData?.map((item, index) => (
          <div
            key={index}
            onClick={() => handleCarouselItemClick(item.link)}
          >
            <img
              src={getResponsiveImageUrl(item)}
              alt={item.description}
              style={{ width: "100%", height: "26vh" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Carousel
