import React from "react"
import { ReactComponent as ThankYou } from "../../../Assets/icon-thank-you.svg"
import styles from "./ThankYouPage.module.css"
import Button from "../../../Helpers/Button"
import { useNavigate } from "react-router-dom"

const ThankYouPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className={styles.thankYouWrapper}>
        <ThankYou />
        <h1>Thank you!</h1>
        <p>
          Thanks for connectig with simba speaks! We hope you have fun using our
          platform. If you ever need support, please feel free to email us at
          support@simbaspeaks.in.
        </p>
      </div>
      <Button
        onClick={() => {
          navigate("/")
        }}
        id="next"
        buttonInfo="Finish"
      />
    </>
  )
}

export default ThankYouPage
