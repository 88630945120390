import {
  Box,
  Typography,
  Stack,
  InputLabel,
  Button,
  IconButton,
} from "@mui/material"

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { MuiOtpInput } from "mui-one-time-password-input"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { useLocation } from "react-router-dom"
import { successToast, errorToast } from "../utils/toastConfig"

const OtpCard = () => {
  const navigate = useNavigate()

  const [otp, setOtp] = useState("")
  const [message, setMessage] = useState("")
  const [resendDisabled, setResendDisabled] = useState(true)
  const [timer, setTimer] = useState(60)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const email = queryParams.get("email")

  const handleChange = (newValue) => {
    setOtp(newValue)
  }

  const handleVerifyOTP = () => {
    if (!otp) {
      setMessage("Please enter the OTP.")
      errorToast("Please enter the OTP.")
    } else {
      axios
        .post("api/auth/verifyOTP", {
          email: email,
          otp: otp,
        })
        .then((response) => {
          setMessage(response.data.message)
          if (response.data.success) {
            successToast("OTP verified successfully!")
            navigate(`/auth/newpassword?email=${email}`) // Redirect to the new password page
          }
        })
        .catch((error) => {
          setMessage("Invalid OTP. Please check your OTP and try again.")
          errorToast("Invalid OTP. Please check your OTP and try again.")
        })
    }
  }
  const handleResendOTP = () => {
    if (email) {
      // Make an API call to request a new OTP
      axios
        .post("api/auth/resendOTP", { email })
        .then((response) => {
          if (response.data.success) {
            setMessage("New OTP sent. Please check your email.")
            successToast("New OTP sent successfully!")
            setResendDisabled(true)
            setTimer(300)
          } else {
            setMessage("Failed to resend OTP. Please try again later.")
            errorToast("Failed to resend OTP. Please try again later.")
          }
        })
        .catch((error) => {
          setMessage("An error occurred. Please try again.")
          errorToast("An unexpected error occurred. Please try again.")
        })
    } else {
      setMessage("Email address is required to resend OTP.")
    }
    setResendDisabled(true)
    setTimer(300)
  }

  useEffect(() => {
    let interval
    if (timer > 0 && resendDisabled) {
      interval = setInterval(() => {
        setTimer(timer - 1)
        if (timer === 1) {
          setResendDisabled(false) // Enable the Resend OTP button after 5 minutes
          clearInterval(interval)
        }
      }, 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [timer, resendDisabled])

  return (
    <Box
      height="max-content"
      ml={{ xs: 0, md: 30 }}
      padding="20px 0px"
      bgcolor="white"
      width={{ xs: "100vw", md: "25vw" }}
      border={{ xs: 0, md: "1px solid #999999" }}
      borderRadius={2}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Box
          display="flex"
          justifyContent="start"
          width="70%"
        >
          <IconButton
            aria-label="back-button"
            onClick={() => {
              navigate(-1)
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Box>
        <img
          className="logo_tagline"
          src="/logo_tagline.png"
          alt=""
          srcSet=""
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h3"
            fontWeight={700}
            fontSize="25px"
          >
            Code Verification
          </Typography>
          <Typography
            variant="p"
            color="#3d3d3d"
            fontSize="16px"
            textAlign="center"
          >
            Enter OTP (One Time Password) <br /> sent to {email}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="70%"
          gap={2}
          mt={10}
        >
          <Box>
            <InputLabel sx={{ color: "#000000" }}>OTP</InputLabel>
            <MuiOtpInput
              onChange={handleChange}
              value={otp}
            />
          </Box>
          <p>{message}</p>
          {/* <Link to="/auth/newpassword"> */}
          <Button
            variant="contained"
            fullWidth
            onClick={handleVerifyOTP}
          >
            Verify OTP
          </Button>
          {/* </Link> */}
          <Button
            variant="contained"
            fullWidth
            onClick={handleResendOTP}
            disabled={resendDisabled}
          >
            Resend OTP {resendDisabled ? `in ${timer} seconds` : ""}
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}

export default OtpCard
