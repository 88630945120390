import React, { useState, useContext } from "react"
import Axios from "axios"
import { AuthContext } from "../context/AuthContext"
import { CartContext } from "../context/CartContext"
import { useNavigate } from "react-router-dom"
import { successToast, errorToast } from "../utils/toastConfig"

const ProductCard = ({
  name,
  price,
  image,
  originalprice,
  productId,
  averageRating,
}) => {
  const [addedToCart, setAddedToCart] = useState(false)
  const { data } = useContext(AuthContext)
  const { dispatch } = useContext(CartContext)
  const navigate = useNavigate()

  const addToCart = () => {
    if (!data || !data?.token) {
      navigate("/cart")
      return
    }

    Axios.post(
      "api/addcart",
      {
        productId,
        quantity: 1,
      },
      {
        headers: {
          authorisation: JSON.parse(localStorage.getItem("data")).token,
        },
      }
    )
      .then((response) => {
        successToast("Item added to cart successfully!")
        setAddedToCart(true)
        dispatch({ type: "SET_CART", payload: response.data })
      })
      .catch((error) => {
        console.error("Error adding to cart:", error)
        errorToast("Failed to add item to cart. Please try again later.")
      })
  }

  const handleProductClick = () => {
    // Navigate to the product details page
    navigate(`/products/${productId}`)
  }

  return (
    <div
      className="relative m-10 flex w-full max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md"
      onClick={handleProductClick}
      style={{ cursor: "pointer" }}
    >
      <div className="relative mx-3 mt-3 flex h-60 overflow-hidden rounded-xl">
        <img
          className="object-cover w-full h-full "
          src={image}
          alt={name}
        />
        {/* <span className="absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white">
          80% OFF
        </span> */}
      </div>
      <div className="mt-4 px-5 pb-5">
        <div>
          <h5 className="text-xl tracking-tight text-slate-900">{name}</h5>
        </div>
        <div className="mt-2 mb-5 flex items-center justify-between">
          <p>
            <span className="text-3xl font-bold text-slate-900">₹{price}</span>
            <span className="text-sm text-slate-900 line-through">
              ₹{originalprice}
            </span>
          </p>
          <div className="flex items-center">
            {/* {[1, 2, 3, 4, 5].map((index) => (
              <StarIcon
                key={index}
                className="h-5 w-5 text-yellow-300"
              />
            ))} */}
            <span className="mr-2 ml-3 rounded bg-yellow-200 px-2.5 py-0.5 text-xs font-semibold">
              {averageRating}
            </span>
          </div>
        </div>

        <button
          onClick={() => addToCart()}
          className={`flex items-center justify-center rounded-md object-cover w-full h-full bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300 ${
            addedToCart ? " opacity-50" : "cursor-pointer"
          }`}
          disabled={addedToCart}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
          {addedToCart ? "Added" : "Add to Cart"}
        </button>
      </div>
    </div>
  )
}

export default ProductCard
