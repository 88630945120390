import React, { useState, useEffect } from "react"
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material"
import axios from "axios"
import { Link } from "react-router-dom"

export default function ChildProfile() {
  const [child, setChild] = useState({
    name: "",
    age: "",
    gender: "",
    initialComplaint: "",
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("api/my_children", {
          headers: {
            authorisation: JSON.parse(localStorage.getItem("data")).token,
          },
        })

        if (response.data.children.length > 0) {
          const firstChild = response.data.children[0]
          setChild({
            name: firstChild.name,
            age: firstChild.age,
            gender: firstChild.gender,
            initialComplaint: firstChild.initialComplaint,
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])

  return (
    <Container>
      <Grid
        item
        lg={8}
      >
        {child.name ? (
          <Card sx={{ mb: 4, background: "#f9f8f0" }}>
            <CardContent>
              <Typography
                variant="h5"
                gutterBottom
              >
                Child Details
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={3}
                >
                  <Typography variant="body1">Name: </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    {child.name}
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container>
                <Grid
                  item
                  xs={3}
                >
                  <Typography variant="body1">Age: </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    {child.age}
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container>
                <Grid
                  item
                  xs={3}
                >
                  <Typography variant="body1">Gender: </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    {child.gender}
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container>
                <Grid
                  item
                  xs={3}
                >
                  <Typography variant="body1">Initial Complaint: </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    {child.initialComplaint}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : (
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Typography
                variant="h5"
                gutterBottom
              >
                No Child Information
              </Typography>
              <Link
                to="/auth/childdetails"
                style={{ textDecorationLine: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                >
                  Add Child
                </Button>
              </Link>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Container>
  )
}
