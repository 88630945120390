import React from "react"
import "./Tracker.css"
import { Link } from "react-router-dom"
export const Tracker = () => {
  return (
    <>
      <div className="tracker">
        <img
          src="/Goal Progress.png"
          alt="img"
          className="trackData"
        />
        <div className="trackDatatxt">
          <h2>
            Track your child’s development and watch their communication skills
            skyrocket.
          </h2>
          <span>
            Your child’s personalized care plan features weekly measured goals,
            bringing them closer to graduation.
          </span>
          <Link to="/products">
            <div className="frstPart-button cursor-pointer">Get Started</div>
          </Link>
        </div>
      </div>
    </>
  )
}
