import React, { useState } from "react"
import styles from "./Dropdown.module.css"

const Dropdown = ({ options, value, setValue, label }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleSelectOption = (selectedValue) => {
    setValue(selectedValue)
    setIsOpen(false)
  }

  return (
    <div className={styles.dropdown}>
      <label>{label}</label>
      <div className={styles.dropdownWrapper}>
        <div
          className={styles.selectedOption}
          onClick={handleToggleDropdown}
        >
          {value || "Select an option"}
        </div>
        {isOpen && (
          <div className={styles.options}>
            {options.map((option, index) => (
              <div
                key={index}
                className={styles.option}
                onClick={() => handleSelectOption(option.value)}
              >
                {option.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Dropdown
