import React from "react"
import { Box } from "@mui/material"
import { Typography } from "@mui/material"
import { Link } from "react-router-dom"
import YouTubeIcon from "@mui/icons-material/YouTube"
import FacebookIcon from "@mui/icons-material/Facebook"
import TwitterIcon from "@mui/icons-material/Twitter"
import InstagramIcon from "@mui/icons-material/Instagram"
import LinkedInIcon from "@mui/icons-material/LinkedIn"

const Footer = () => {
  return (
    <Box
      component="footer"
      py={4}
      display="flex"
      justifyContent="space-evenly"
      alignItems="start"
      bgcolor="#1F1F1F"
      sx={{
        flexDirection: {
          xs: "column",
          md: "row",
        },
        px: {
          xs: "16px",
          md: "0",
        },
        mt: {
          xs: "20px", // Adjust this margin as needed for phone view
          md: "0",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          sx={{ width: { xs: "90vw", sm: "30vw" } }}
        >
          <Typography
            variant="p"
            fontWeight={800}
            color="white"
          >
            About Simba Speaks
          </Typography>
          <Typography
            variant="p"
            color="white"
          >
            Simba Speaks is a revolutionary online speech therapy platform. Our
            primary goal is to empower parents to become their child's speech
            therapist. We believe in fostering a supportive environment where
            parents play a crucial role in their child's speech development
            journey.
          </Typography>
        </Box>
        <Box
          display="flex"
          gap={1}
        >
          <Link
            to="https://www.youtube.com/channel/UC3Vq9mgJGGR5PrGxPu8Iq2A"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTubeIcon style={{ color: "#ffffff" }} />
          </Link>
          <Link
            to="https://www.facebook.com/people/Simba-Speaks/61551943646117/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon style={{ color: "#ffffff" }} />
          </Link>
          <TwitterIcon style={{ color: "#ffffff" }} />

          <Link
            to="https://instagram.com/simbaspeaks.in?igshid=MzRlODBiNWFlZA=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon style={{ color: "#ffffff" }} />
          </Link>
          <Link
            to="https://www.linkedin.com/company/simba-speaks/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon style={{ color: "#ffffff" }} />
          </Link>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <Typography
          variant="p"
          fontWeight={800}
          color="white"
        >
          Company
        </Typography>
        <Link to="/">
          <Typography
            variant="p"
            color="white"
          >
            Home
          </Typography>
        </Link>
        <Link to="/about">
          <Typography
            variant="p"
            color="white"
          >
            About us
          </Typography>
        </Link>
        {/* <Typography
          variant="p"
          color="white"
        >
          Team
        </Typography> */}
        <Typography
          variant="p"
          color="white"
        >
          Blogs
        </Typography>
        <Link to={"/products"}>
          <Typography
            variant="p"
            color="white"
          >
            Products
          </Typography>
        </Link>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <Typography
          variant="p"
          fontWeight={800}
          color="white"
        >
          Learn More
        </Typography>
        <Link to={"/pricing"}>
          <Typography
            variant="p"
            color="white"
          >
            Pricing
          </Typography>
        </Link>
        <Link to={"/policy/terms"}>
          <Typography
            variant="p"
            color="white"
          >
            Terms & Conditions
          </Typography>
        </Link>
        <Link to={"/screener"}>
          <Typography
            variant="p"
            color="white"
          >
            Take our screener
          </Typography>
        </Link>
        <Link to={"policy/refund"}>
          <Typography
            variant="p"
            color="white"
          >
            Refund Policy
          </Typography>
        </Link>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <Typography
          variant="p"
          fontWeight={800}
          color="white"
        >
          Contact Us
        </Typography>
        <Typography
          variant="p"
          color="white"
        >
          Phone: +91 9304026228
        </Typography>
        <Typography
          variant="p"
          color="white"
        >
          info@simbaspeaks.in
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer
