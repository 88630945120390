import { useReducer, useEffect, createContext } from "react"

export const AuthContext = createContext()

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        data: action.payload,
      }
    case "LOGOUT":
      return {
        data: null,
      }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, {
    data: localStorage.getItem("data")
      ? JSON.parse(localStorage.getItem("data"))
      : null,
  })

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("data"))
    if (data) {
      dispatch({ type: "LOGIN", payload: data })

      const logoutTimeout = setTimeout(() => {
        dispatch({ type: "LOGOUT" })
        localStorage.removeItem("data")
      }, 24 * 60 * 60 * 1000)

      return () => {
        clearTimeout(logoutTimeout)
      }
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        data: state.data,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
