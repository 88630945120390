import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import TextFieldsIcon from "@mui/icons-material/TextFields"
import ArticleIcon from "@mui/icons-material/Article"
import StarIcon from "@mui/icons-material/Star"
import { ExpandMore } from "@mui/icons-material"

import { useState } from "react"
import Carousel from "../Components/carousel/Carousel"
import Footer from "../Components/Footer"
import Testimonials from "../Components/testimonials/Testimonials"

const Landing = () => {
  const [expandedAccordion, setExpandedAccordion] = useState(null)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null)
  }
  return (
    <Box>
      <Carousel />
      {/* Headind 1 Section  */}
      <Box
        py={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="#FBEFEF"
        gap={2}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          px: {
            xs: "5px",
            md: "0",
          },
        }}
      >
        <img
          className="mic-image"
          src="/heading1.png"
          alt="heading1 "
        />
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{ width: { xs: "80vw", sm: "40vw" } }}
        >
          <Typography
            variant="h2"
            fontWeight={700}
            sx={{ fontSize: { xs: "20px", md: "40px" } }}
          >
            Parent Led Speech therapy that makes your home a classroom
          </Typography>
          <Typography variant="p">
            Partner with an experienced speech therapist online for live, 1-on-1
            sessions from home — with weekly practice activities and support to
            reach your goals faster.
          </Typography>
        </Box>
      </Box>
      {/* Heading 2 Section  */}
      <Box
        py={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="#F3F7FC"
        gap={2}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          px: {
            xs: "5px",
            md: "0",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{ width: { xs: "80vw", sm: "40vw" } }}
        >
          <Typography
            variant="h2"
            fontWeight={700}
            sx={{ fontSize: { xs: "20px", md: "40px" } }}
          >
            Any age is the right age to start.
          </Typography>
          <Typography variant="p">
            Children learn best from the people they love most—you! During your
            toddler's sessions, you'll learn simple, effective strategies to
            incorporate at home.Join your child for fun, interactive virtual
            sessions, where you'll learn tips from your speech therapist to
            easily apply throughout daily life.
          </Typography>
          <Box
            display="flex"
            gap={2}
          >
            <Box
              component="span"
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <CalendarMonthIcon
                fontSize="small"
                style={{ color: "#A25C32" }}
              />
              <Typography variant="p">Flexible Scheduling </Typography>
            </Box>
            <Box
              component="span"
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <TextFieldsIcon
                fontSize="small"
                style={{ color: "#A25C32" }}
              />
              <Typography variant="p">Text support</Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            gap={2}
          >
            <Box
              component="span"
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <StarIcon
                fontSize="small"
                style={{ color: "#A25C32" }}
              />
              <Typography variant="p">Effective for all ages</Typography>
            </Box>
            <Box
              component="span"
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <ArticleIcon
                fontSize="small"
                style={{ color: "#A25C32" }}
              />
              <Typography variant="p">Research Based</Typography>
            </Box>
          </Box>
        </Box>

        <img
          className="mic-image"
          src="/heading2.png"
          alt="heading2"
        />
      </Box>
      {/* Heading 3 Section  */}
      <Box
        py={4}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Typography
          variant="h3"
          px={5}
          fontWeight={700}
          sx={{
            fontSize: { xs: "20px", md: "40px" },
          }}
        >
          We're here to help with all of your communication needs.
        </Typography>
        <Box
          display="flex"
          gap={3}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Typography
            variant="p"
            textAlign="justify"
            sx={{ width: { xs: "80vw", sm: "32vw" } }}
          >
            In the domain of speech therapy, our licensed and experienced
            therapists are dedicated to delivering high-quality care for
            children with communication issues. We recognize that parents are
            pivotal advocates for their children's well-being and development,
            and our mission is to provide them with the knowledge and tools
            needed to support their child's speech development journey. Our
            therapists work closely with parents, providing them with insights,
            strategies, and personalized training to actively participate in
            their child's speech development.
          </Typography>
          <Typography
            variant="p"
            textAlign="justify"
            sx={{ width: { xs: "80vw", sm: "32vw" } }}
          >
            When you choose our services, you're gaining access to a
            comprehensive support system. Our primary goal is to assist parents
            and families as they embark on this crucial journey. By equipping
            them with expert guidance and tools, we aim to empower parents in
            nurturing their child's speech development, setting them on a path
            to a brighter future.
          </Typography>
        </Box>
        {/* Tiles Section */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            // bgcolor: { md: "#FBEFEF" },
            "align-items": "stretch",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            bgcolor="#FBEFEF"
            gap={2}
            p={2}
            sx={{
              width: { xs: "80vw", sm: "50vw", md: "20vw" },
              alignItems: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <img
              className="tile-logo"
              src="/home -1.png"
              alt="tile1"
              // style={{ width: "236px", height: "320px", borderRadius: "10px" }}
            />
            <Typography
              variant="h4"
              fontWeight={600}
              fontSize="30px"
            >
              Autism Spectrum Disorder
            </Typography>
            <Typography
              variant="p"
              fontSize="15px"
            >
              This guide explains how autism can affect your child's
              communication abilities, common signs of autism, and how speech
              therapy can help autistic people.
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            bgcolor="#FBEFEF"
            gap={2}
            p={2}
            sx={{
              width: { xs: "80vw", sm: "50vw", md: "20vw" },
              alignItems: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <img
              className="tile-logo"
              src="/home -2.png"
              alt="tile1"
              // style={{ width: "236px", height: "320px", borderRadius: "10px" }}
            />
            <Typography
              variant="h4"
              fontWeight={600}
              fontSize="30px"
            >
              Early Childhood Development
            </Typography>
            <Typography
              variant="p"
              fontSize="15px"
            >
              Learn about the stages of early language development, the signs of
              speech and language delays, and how speech therapy can help
              diagnosed and treated.
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            bgcolor="#FBEFEF"
            gap={2}
            p={2}
            sx={{
              width: { xs: "80vw", sm: "50vw", md: "20vw" },
              alignItems: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <img
              className="tile-logo"
              src="/home-3.png"
              alt="tile1"
              // style={{ width: "236px", height: "320px", borderRadius: "10px" }}
            />
            <Typography
              variant="h4"
              fontWeight={600}
              fontSize="30px"
            >
              Speech Delay Intervention
            </Typography>
            <Typography
              variant="p"
              fontSize="15px"
            >
              This guide explains signs and symptoms of a speech delay, how
              speech delay is diagnosed and treated, and tips for helping your
              child learn to talk.
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* FAQ Section */}
      <Box
        py={4}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        bgcolor="#ffd7b5"
        alignItems="center"
        gap={2}
      >
        <Typography
          variant="h4"
          px="5px"
        >
          Frequently Asked Questions
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: { xs: "95vw", md: "60vw" } }}
          gap={2}
        >
          <Accordion
            elevation={0}
            expanded={expandedAccordion === "panel1"}
            onChange={handleChange("panel1")}
            sx={{
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    backgroundColor: "#ffb38a",
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                    "@media (min-width: 600px)": {
                      width: "3rem",
                      minWidth: "3rem",
                      height: "3rem",
                    },
                  }}
                />
              }
              aria-label="Expand"
              aria-controls="1a-content"
              id="1a-header"
            >
              <Typography fontWeight={600}>
                How do I get started with your platform?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              To begin, sign up on our platform and complete a brief
              questionnaire regarding your child. From there, you can explore
              our range of plans and subscriptions. The initial step involves
              booking a consultation and assessment plan. Following this, our
              speech therapist will provide personalized guidance to tailor a
              program suited to your child's needs.
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            expanded={expandedAccordion === "panel2"}
            onChange={handleChange("panel2")}
            sx={{
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    backgroundColor: "#ffb38a",
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                    "@media (min-width: 600px)": {
                      width: "3rem",
                      minWidth: "3rem",
                      height: "3rem",
                    },
                  }}
                />
              }
              aria-label="Expand"
              aria-controls="1a-content"
              id="1a-header"
            >
              <Typography fontWeight={600}>
                How do I know if my child needs speech therapy?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              Effective communication is fundamental throughout our lives.
              Speech therapy supports children in achieving age-appropriate
              communication milestones, expressing themselves clearly,
              understanding others, and developing socially. It plays a pivotal
              role in academic success and emotional well-being, among other
              benefits. Take the first step by arranging an evaluation with a
              speech therapist, who will assess your child's communication
              skills and provide expert guidance on the potential benefits of
              speech therapy. Additionally, consider trying our complimentary
              screening tool for initial insights.
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            expanded={expandedAccordion === "panel3"}
            onChange={handleChange("panel3")}
            sx={{
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    backgroundColor: "#ffb38a",
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                    "@media (min-width: 600px)": {
                      width: "3rem",
                      minWidth: "3rem",
                      height: "3rem",
                    },
                  }}
                />
              }
              aria-label="Expand"
              aria-controls="1a-content"
              id="1a-header"
            >
              <Typography fontWeight={600}>
                How long does speech therapy take?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              The duration of speech therapy varies depending on factors such as
              the type and severity of the speech disorder, frequency of
              sessions, home practice, the child's age, and underlying medical
              conditions. For instance, a mild articulation disorder may require
              less time than a severe phonological disorder. Consistent practice
              at home and age also influence progress. Additionally, coexisting
              medical conditions may extend therapy duration. Overall, therapy
              duration is individualized, with regular evaluation ensuring
              effective progress toward goals.
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            expanded={expandedAccordion === "panel4"}
            onChange={handleChange("panel4")}
            sx={{
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    backgroundColor: "#ffb38a",
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                    "@media (min-width: 600px)": {
                      width: "3rem",
                      minWidth: "3rem",
                      height: "3rem",
                    },
                  }}
                />
              }
              aria-label="Expand"
              aria-controls="1a-content"
              id="1a-header"
            >
              <Typography fontWeight={600}>
                At what age can a child start speech therapy?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              Speech therapy is crucial for children experiencing communication
              difficulties, with no minimum age requirement for intervention.
              Starting therapy as early as possible yields significant
              improvements. For instance, toddlers may achieve clearer speech,
              enhancing communication with caregivers and peers. Similarly,
              preschoolers with language delays may develop better vocabulary
              and sentence structure, improving academic and social outcomes.
              Research indicates that early intervention leads to substantial
              gains in communication skills, emphasizing the importance of
              prompt therapy initiation.
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            expanded={expandedAccordion === "panel5"}
            onChange={handleChange("panel5")}
            sx={{
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{
                    backgroundColor: "#ffb38a",
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                    "@media (min-width: 600px)": {
                      width: "3rem",
                      minWidth: "3rem",
                      height: "3rem",
                    },
                  }}
                />
              }
              aria-label="Expand"
              aria-controls="1a-content"
              id="1a-header"
            >
              <Typography fontWeight={600}>
                What does a typical counseling session look like?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              Counseling sessions are individualized to suit each child's care
              plan, with activities specifically geared toward their therapeutic
              objectives. For example, if a parent is attending counseling to
              address behavioral challenges in their child, they may initially
              learn coping strategies and gradually practice implementing them
              in various situations. The speech therapist will aim to
              incorporate as many repetitions of each activity as possible
              during the session to enhance its impact. Continuous assessment of
              the child's response to these interventions is essential, and
              modifications to the treatment plan are made as needed to support
              swift progress.
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Container>
        <Testimonials />
      </Container>

      {/* Footer Section */}
      <Footer />
    </Box>
  )
}

export default Landing
