import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "./testimonials.css"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Autoplay, Pagination, Navigation } from "swiper/modules"

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      name: "Dr Rashmi S",
      content:
        "Ma’am is very approachable and supportive throughout the journey. What I loved the most was the therapy was integrated into my child’s daily routine and not like a burden.",
      image: "/woman.png",
    },
    {
      id: 2,
      name: "Swati Agarwal",
      content:
        "Dr Tanu is truly dedicated to her work. Thanks to her commitment, my child has made remarkable progress, especially in speech. I'm thrilled with the improvements I've seen. Highly recommended!",
      image: "/woman.png",
    },
    {
      id: 3,
      name: "Ruchi Singh",
      content:
        "The precise and accurate diagnosis pinpointed my child's needs perfectly, what she was lacking and guided us on overcoming them effectively. Their continuous parental counselling throughout the therapy sessions was invaluable.",
      image: "/woman.png",
    },
    {
      id: 4,
      name: "Prajakta Gawande",
      content:
        "Dr. Tanu's dedication is remarkable. Under her guidance, my child has made tremendous progress, particularly in speech. I'm delighted with the overall improvement I've witnessed. Highly recommended for her committed and effective approach.",
      image: "/woman.png",
    },
    {
      id: 5,
      name: "Shuchismita Pradhan",
      content:
        "My child has experienced a genuine transformation in speech therapy thanks to Dr. Tanu's targeted and goal-oriented approach. She worked carefully on speech sounds, identifying areas for improvement through comprehensive assessment and tailored guidance. Her guidance and assistance have enabled us as parents to successfully participate in speech development at home. We're happy to see such substantial progress in a short period. ",
      image: "/woman.png",
    },
    {
      id: 6,
      name: "Dr. Hadiya Naureen",
      content:
        "Clear and specific instructions on therapies, without scaring parents, giving hope: I was blown away when Tanu Madam said my son is a gestalt language processor. After researching and observing, I found everything related to my son. Tanu Madam listened to me thoroughly, which helped me explain my problems without any difficulty. She is patient and very calm, which actually relieved my anxiety. I wish you all the best, ma'am. You are doing great.",
      image: "/woman.png",
    },
  ]
  return (
    <>
      <div className="head">
        <h3>Our wall of love</h3>
        <p>Read what our community members are saying about Simba Speaks…</p>
      </div>
      <Swiper
        navigation={true}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.id}>
            <div className="container">
              <div className="testi">
                <div className="body">
                  <ul>
                    <li>
                      <div className="wrapper">
                        <div className="thumbnail">
                          <img
                            src={testimonial.image}
                            alt=""
                          />
                        </div>
                        <div className="aside">
                          <p>{testimonial.content}</p>
                          <div className="name">
                            <h4>{testimonial.name}</h4>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default Testimonials
