import React, { useEffect, useState } from "react"
import axios from "axios"
import SubscriptionCard from "../SubscriptionCard"

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await axios.get("/api/subscriptions", {
          headers: {
            authorisation: JSON.parse(localStorage.getItem("data")).token,
          },
        })
        setSubscriptions(response.data.subscriptions)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchSubscriptions()
  }, [])

  if (loading) {
    return <p className="text-center">Loading...</p>
  }

  if (error) {
    return <p className="text-center text-red-600">Error: {error}</p>
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-6 text-center">
        Your Subscriptions
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {subscriptions.map((subscription) => (
          <SubscriptionCard
            key={subscription.productId}
            subscription={subscription}
          />
        ))}
      </div>
    </div>
  )
}

export default Subscriptions
