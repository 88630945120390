import "./App.css"
import ForgotPasswordCard from "./Components/ForgotPassword"
import LoginCard from "./Components/LoginCard"
import Navbar from "./Components/Navbar"
import NewPassCard from "./Components/NewPassCard"
import OtpCard from "./Components/OtpCard"
import SignUpCard from "./Components/SignupCard"
import TermsAndConditions from "./Components/Policy/TermsAndConditions"
import ContactUs from "./Components/Policy/Contactus"
import Landing from "./Pages/Landing"
import Policy from "./Pages/Policy"
import Products from "./Pages/Products"
import SignUp from "./Pages/Signup"
import { Routes, Route } from "react-router-dom"
import Refund from "./Components/Policy/Refund"
import Privacy from "./Components/Policy/Privacy"
import Shipping from "./Components/Policy/Shipping"
import ProtectedRoute from "./Routes/ProtectedRoute"
import Cart from "./Components/Cart"
import Checkout from "./Components/Checkout"
import Account from "./Pages/Account"
import MyProfile from "./Components/Profile/ProfilePage"
import ChildProfile from "./Components/Profile/ChildDetails"
import Community from "./Components/Community "
import axios from "axios"
import Loading from "./Components/Loading"
import { useEffect, useState } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import LoadingBar from "react-top-loading-bar"
import PaymentSuccessPage from "./Components/PaymentSuccessPage"
import RetryPaymentPage from "./Components/RetryPaymentPage"
import MyOrdersPage from "./Components/Profile/MyOrdersPage"
import ComingSoon from "./Components/ComingSoon"

import ScreenerResult from "./Components/screener/ScreenerResult"
import Program from "./Pages/Program"
import EmailVerify from "./Components/email-verify/EmailVerify"
import QuestionsPage from "./Components/screener/QuestionsPage"
import AgeSelectionPage from "./Components/screener/AgeSelectionPage"
import ProductDetail from "./Components/ProductDetail"
import About from "./Components/About"
import Pricing from "./Components/Pricing"
import ChildDetail from "./Components/childdetails/Main"
import Blog from "./Components/Blog/Blog"
import Subscription from "./Components/Profile/Subscription"

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
function App() {
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        setLoading(true)
        return config
      },
      (error) => {
        return Promise.reject
      }
    )

    axios.interceptors.response.use(
      (config) => {
        setLoading(false)
        return config
      },
      (error) => {
        return Promise.reject
      }
    )
  }, [])

  useEffect(() => {
    if (loading) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
  }, [loading])

  return (
    <>
      <LoadingBar
        color="#ed9121"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Navbar />
      <Loading show={loading} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
      />

      <Routes>
        <Route
          path="/"
          element={<Landing />}
        />
        <Route
          path="auth"
          element={<SignUp />}
        >
          <Route
            path="signup"
            element={<SignUpCard />}
          />
          <Route
            path="login"
            element={<LoginCard />}
          />
          <Route
            path="forgotpassword"
            element={<ForgotPasswordCard />}
          />
          <Route
            path="otp"
            element={<OtpCard />}
          />
          <Route
            path="newpassword"
            element={<NewPassCard />}
          />
        </Route>
        <Route
          path="auth/childdetails"
          element={
            <ProtectedRoute>
              <ChildDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="success/:id"
          element={
            <ProtectedRoute>
              <PaymentSuccessPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="retry"
          element={
            <ProtectedRoute>
              <RetryPaymentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="policy"
          element={<Policy />}
        >
          <Route
            path="terms"
            element={<TermsAndConditions />}
          />
          <Route
            path="contact"
            element={<ContactUs />}
          />
          <Route
            path="refund"
            element={<Refund />}
          />
          <Route
            path="privacy"
            element={<Privacy />}
          />
          <Route
            path="shipping"
            element={<Shipping />}
          />
        </Route>
        <Route
          path="/about"
          element={<About />}
        />
        <Route
          path="/blog"
          element={<Blog />}
        />
        <Route
          path="/pricing"
          element={<Pricing />}
        />
        <Route
          path="/products"
          element={<Products />}
        />

        <Route
          path="/community"
          element={<Community />}
        />
        <Route
          path="/soon"
          element={<ComingSoon />}
        />
        <Route
          path="/screener"
          element={<AgeSelectionPage />}
        />
        <Route
          path="/screener/questions/:age"
          element={<QuestionsPage />}
        />
        <Route
          path="/users/:id/verify/:token"
          element={<EmailVerify />}
        />
        <Route
          path="/products/:productId"
          element={<ProductDetail />}
        />
        <Route
          path="/screener/results/:age"
          element={<ScreenerResult />}
        />
        <Route
          path="/program"
          element={<Program />}
        />
        <Route
          path="/cart"
          element={<Cart />}
        />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />
        <Route
          path="Profile"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        >
          <Route
            path="myprofile"
            element={
              <ProtectedRoute>
                <MyProfile />
              </ProtectedRoute>
            }
          />

          <Route
            path="childprofile"
            element={
              <ProtectedRoute>
                <ChildProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="myorder"
            element={
              <ProtectedRoute>
                <MyOrdersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="subscription"
            element={
              <ProtectedRoute>
                <Subscription />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </>
  )
}

export default App
