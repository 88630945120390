import React from "react"
import { Banner } from "../Components/program/Banner/Banner"
import { Research } from "../Components/program/Research/Research"
import { Tracker } from "./../Components/program/Tracker/Tracker"
import { UserCriteria } from "./../Components/program/UserCriteria/UserCriteria"
import Quesiton from "../Components/program/Question/Question"
import Footer from "../Components/Footer"
import Slide from "../Components/program/Rotating/Slide"
import Compare from "../Components/program/Compare/Compare"

const Program = () => {
  return (
    <div className="overflow-x-hidden  mx-auto">
      <Banner />
      <Research />
      <UserCriteria />
      <Compare />
      <Tracker />
      <Slide />
      <Quesiton />
      <Footer />
    </div>
  )
}

export default Program
