import React from "react"
import styled, { keyframes, css } from "styled-components"
import {
  FaStar,
  FaMobileAlt,
  FaDumbbell,
  FaBook,
  FaChalkboardTeacher,
  FaCalendarAlt,
} from "react-icons/fa"
const iconComponents = [
  FaStar,
  FaMobileAlt,
  FaDumbbell,
  FaBook,
  FaChalkboardTeacher,
  FaCalendarAlt,
]

function Slide() {
  const row1 = [
    "Personalized care plans",
    "Text your therapist anytime",
    "RCI Certified",
    "Thousands of practice activities",
    "Guidance Sessions",
    "Available 7 days/week + evenings",
  ]

  return (
    <AppContainer>
      <Wrapper>
        <Marquee key={row1}>
          <MarqueeGroup>
            {row1.map((el, index) => (
              <ImageGroup key={el}>
                <Text>
                  {React.createElement(iconComponents[index])} {el}
                </Text>
              </ImageGroup>
            ))}
          </MarqueeGroup>
          <MarqueeGroup>
            {row1.map((el, index) => (
              <ImageGroup key={el}>
                <Text>
                  {React.createElement(iconComponents[index])} {el}
                </Text>
              </ImageGroup>
            ))}
          </MarqueeGroup>
        </Marquee>
      </Wrapper>
    </AppContainer>
  )
}

export default Slide

const AppContainer = styled.div`
  /* width: 100vw; */

  color: #000000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
`

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Text = styled.div`
  font-size: 15px;
  font-weight: 500;

  margin: 0px -40px 0px -40px;
  color: #02203c;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`

const Marquee = styled.div`
  display: flex;
  overflow: hidden;
  user-select: none;
  /* width: 100vw; */
  /* mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  ); */
`

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 50s linear infinite;
`

const MarqueeGroup = styled.div`
  ${common}
  width: 100%;
  overflow: hidden;
`

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
  /* width: clamp(0rem, -5rem + 42vmin, 30rem); */
  /* padding: calc(clamp(0rem, -5rem + 42vmin, 30rem) / 10); */
`

// const h3 = styled.img`
//   object-fit: contain;
//   width: 100%;
//   height: 100%;
//   /* border: 1px solid black; */
//   border-radius: 0.5rem;
//   aspect-ratio: 16/9;
//   padding: 5px 20px;
//   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
// `
