import React from "react"

const SubscriptionCard = ({ subscription }) => {
  const { productName, startDate, endDate, daysRemaining } = subscription

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden transform transition-transform hover:scale-105">
      <div className="p-6">
        <h3 className="text-2xl font-bold mb-2 text-gray-800">{productName}</h3>
        <p className="text-gray-600 mb-2">
          <strong>Start Date:</strong>{" "}
          {new Date(startDate).toLocaleDateString()}
        </p>
        <p className="text-gray-600 mb-2">
          <strong>End Date:</strong> {new Date(endDate).toLocaleDateString()}
        </p>
        <p
          className={`mt-4 text-lg ${
            daysRemaining <= 5 ? "text-red-600" : "text-green-600"
          }`}
        >
          <strong>Days Remaining:</strong> {daysRemaining}
        </p>
      </div>
      <div className="p-4 bg-gray-100 border-t border-gray-200">
        <button className="w-full bg-blue-500 text-white py-2 rounded-lg font-semibold transition-colors hover:bg-blue-600">
          Manage Subscription
        </button>
      </div>
    </div>
  )
}

export default SubscriptionCard
