import React, { useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Typography,
  Stack,
  TextField,
  InputLabel,
  Button,
  IconButton,
} from "@mui/material"
import { successToast, errorToast } from "../utils/toastConfig"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"

const ForgotPasswordCard = () => {
  const [email, setEmail] = useState("")
  // const [message, setMessage] = useState("")
  const navigate = useNavigate()

  const handleRequestOTP = () => {
    if (!email) {
      errorToast("Please provide your email address.")
    } else {
      axios
        .post("api/auth/forgetpassword", { email })
        .then((response) => {
          // setMessage(response.data.message)
          if (response.data.success) {
            successToast(response.data.message)
            navigate(`/auth/otp?email=${email}`)
          } else {
            errorToast(response.data.message)
          }
        })
        .catch((error) => {
          errorToast(
            "An unexpected error occurred. Please try again affter 1 min."
          )
        })
    }
  }
  return (
    <Box
      height="max-content"
      ml={{ xs: 0, md: 30 }}
      padding="20px 0px"
      bgcolor="white"
      width={{ xs: "100vw", md: "25vw" }}
      border={{ xs: 0, md: "1px solid #999999" }}
      borderRadius={2}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Box
          display="flex"
          justifyContent="start"
          width="70%"
        >
          <IconButton
            aria-label="back-button"
            onClick={() => {}}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Box>
        <img
          className="logo_tagline"
          src="logo_tagline.png"
          alt=""
          srcSet=""
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h3"
            fontWeight={700}
            fontSize="25px"
          >
            Forgot Password
          </Typography>
          <Typography
            variant="p"
            color="#3d3d3d"
            fontSize="16px"
            textAlign="center"
          >
            Provide your account's email for <br /> which you want to reset your{" "}
            <br /> password
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="start"
          flexDirection="column"
          gap={2}
          mt={10}
        >
          <Box>
            <InputLabel sx={{ color: "#000000" }}>Email</InputLabel>
            <TextField
              hiddenLabel
              placeholder="Enter Your Email"
              size="small"
              style={{ width: "260px" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Button
            variant="contained"
            fullWidth
            onClick={handleRequestOTP}
          >
            Request OTP
          </Button>
          <Typography
            variant="p"
            color="#3d3d3d"
            fontSize="16px"
            textAlign="center"
          >
            {/* {message} */}
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}

export default ForgotPasswordCard
