import React, { useState, useEffect, useContext } from "react"
import {
  Container,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Paper,
  IconButton,
  Checkbox,
} from "@mui/material"
import FormControlLabel from "@mui/material/FormControlLabel"

import Box from "@mui/material/Box"
import DeleteIcon from "@mui/icons-material/Delete"
import Axios from "axios"
import { AuthContext } from "../context/AuthContext"
import { Link } from "react-router-dom"
import EmptyCart from "./EmptyCart"
import UnauthorizedUserCart from "./UnauthorizedUserCart"
import Checkout from "./Checkout"
import { successToast, errorToast } from "../utils/toastConfig"
import { CartContext } from "../context/CartContext"
import { Modal, Backdrop, Fade } from "@mui/material"

const quantityOptions = [1, 2, 3, 4, 5]

function Cart() {
  const [openTcModal, setOpenTcModal] = useState(false)

  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [showCheckout, setShowCheckout] = useState(false)
  const [couponCode, setCouponCode] = useState("")
  const [appliedCoupon, setAppliedCoupon] = useState(null)
  const { data } = useContext(AuthContext)
  const { state, dispatch } = useContext(CartContext)
  const cartItems = state.cartItems

  useEffect(() => {
    if (data) {
      Axios.get("api/getCart", {
        headers: {
          authorisation: JSON.parse(localStorage.getItem("data")).token,
        },
      })
        .then((response) => {
          dispatch({ type: "SET_CART", payload: response.data })
        })
        .catch((error) => {
          console.error("Error fetching cart:", error)
        })
    }
  }, [data, dispatch])

  const handleOpenTcModal = () => {
    setOpenTcModal(true)
  }

  const handleCloseTcModal = () => {
    setOpenTcModal(false)
  }

  const updateQuantity = (cartItemId, newQuantity) => {
    Axios.put(`api/update/${cartItemId}`, {
      quantity: newQuantity,
    })
      .then((response) => {
        dispatch({
          type: "UPDATE_QUANTITY",
          payload: { cartItemId, quantity: newQuantity },
        })
        successToast("Item quantity updated successfully!")
      })
      .catch((error) => {
        console.error("Error updating cart item:", error)
        errorToast("Item quantity not updated!")
      })
  }

  const removeItem = (cartItemId) => {
    Axios.delete(`api/remove/${cartItemId}`)
      .then(() => {
        dispatch({ type: "REMOVE_ITEM", payload: { cartItemId } })
        removeCoupon()
        successToast("Item deleted to cart successfully!")
      })
      .catch((error) => {
        console.error("Error removing cart item:", error)
      })
  }

  const applyCoupon = () => {
    if (!couponCode) {
      errorToast("Coupon code cannot be empty")
      return
    }
    if (appliedCoupon) {
      removeCoupon() // If a coupon is applied, remove it
    } else {
      Axios.post(
        "/api/coupons/apply",
        {
          couponCode,
          productIds: cartItems.map((item) => item.product._id),
          subtotal: calculateSubtotal(),
        },
        {
          headers: {
            authorisation: JSON.parse(localStorage.getItem("data")).token,
          },
        }
      )
        .then((response) => {
          // console.log(response.data) // Log the entire response object
          if (response && response.data && response.data.error) {
            errorToast(response.data.error) // Display specific error message
          } else {
            const {
              message,
              subtotal,
              discountAmount,
              terms,
              type,
              discountedSubtotal,
            } = response.data
            // No error, apply coupon
            setAppliedCoupon({
              message,
              subtotal,
              discountAmount,
              terms,
              type,
              discountedSubtotal,
            })
            successToast(message) // Display success message from server
          }
        })
        .catch((error) => {
          console.error("Error applying coupon:" + error, error)
          console.log("Full error response:", error) // Log the entire error object
          errorToast("Failed to apply coupon!") // Display generic error message
        })
    }
  }

  // Inside the renderCouponInfo function
  const removeCoupon = () => {
    setAppliedCoupon(null)
    setCouponCode("") // Clear the coupon code input field
  }

  // Inside the renderCouponInfo function
  const renderCouponInfo = () => {
    if (appliedCoupon) {
      return (
        <div>
          <div className="flex justify-between">
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold" }}
            >
              SubTotal:
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "right", fontWeight: "bold" }}
            >
              ₹{appliedCoupon.subtotal.toFixed(2)}
            </Typography>
          </div>
          <div className="flex justify-between">
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold" }}
            >
              Discount:
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "right", fontWeight: "bold" }}
            >
              - ₹{appliedCoupon.discountAmount.toFixed(2)}
            </Typography>
          </div>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openTcModal}
            onClose={handleCloseTcModal}
            closeAfterTransition
            BackdropProps={{
              timeout: 500,
              style: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Fade in={openTcModal}>
              <div
                style={{
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: 24,
                  maxWidth: 400,
                  margin: "auto",
                  marginTop: 100,
                  maxHeight: "80vh",
                  overflow: "auto",
                }}
              >
                <h2 id="transition-modal-title">Coupon Terms and Conditions</h2>
                <p id="transition-modal-description">{appliedCoupon.terms}</p>
                <Button onClick={handleCloseTcModal}>Close</Button>
              </div>
            </Fade>
          </Modal>
        </div>
      )
    }
    return null
  }

  const calculateSubtotal = () => {
    let subtotal = cartItems.reduce(
      (total, item) => total + item.product.price * item.quantity,
      0
    )
    if (appliedCoupon) {
      subtotal = appliedCoupon.discountedSubtotal
    }
    return subtotal
  }

  const handleCheckboxChange = () => {
    setAgreeToTerms(!agreeToTerms)
  }
  const handleBuyNowClick = () => {
    setShowCheckout(true)
  }

  return (
    <Container sx={{ marginTop: 5 }}>
      {data ? (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={9}
          >
            {showCheckout ? (
              <Checkout
                total={calculateSubtotal()}
                products={cartItems}
                couponCode={couponCode}
              />
            ) : (
              <>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "left", mb: 3 }}
                >
                  Shopping Cart
                </Typography>
                <Card>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell width="80">Qty</TableCell>
                          <TableCell width="80">Price (₹)</TableCell>
                          <TableCell
                            align="right"
                            width="120"
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      {cartItems.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={4}>
                            {data ? (
                              <EmptyCart />
                            ) : (
                              <Typography>No items in your cart</Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableBody>
                          {cartItems.map((item) => (
                            <TableRow key={item._id}>
                              <TableCell>
                                <Grid
                                  container
                                  spacing={2}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                  >
                                    <img
                                      src={item.product.images[0].url}
                                      alt={item.product.name}
                                      style={{ width: "100%" }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      {item.product.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <FormControl fullWidth>
                                  <Select
                                    value={item.quantity}
                                    onChange={(e) =>
                                      updateQuantity(item._id, e.target.value)
                                    }
                                  >
                                    {quantityOptions.map((option) => (
                                      <MenuItem
                                        key={option}
                                        value={option}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <div>
                                  <Typography
                                    variant="h6"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    ₹{item.product.price}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                {window.innerWidth < 600 ? (
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => removeItem(item._id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={() => removeItem(item._id)}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  <div style={{ padding: "16px" }}>
                    {cartItems.length > 0 ? (
                      <>
                        <Box
                          display="flex"
                          alignItems="center"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={agreeToTerms}
                                onChange={handleCheckboxChange}
                                color="primary"
                              />
                            }
                            label=<Typography>
                              I agree to the{" "}
                              <Link to="/policy/terms">
                                <u>Terms and Conditions</u>
                              </Link>
                            </Typography>
                          />
                        </Box>
                        <Link
                          to="/products"
                          style={{ textDecorationLine: "none" }}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            sx={{ float: "left", marginBottom: 2 }}
                          >
                            Continue shopping
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ float: "right" }}
                          disabled={!agreeToTerms}
                          onClick={handleBuyNowClick}
                        >
                          Buy Now
                        </Button>
                      </>
                    ) : null}
                  </div>
                </Card>
              </>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            md={3}
          >
            <Card>
              <div style={{ padding: "16px" }}>
                <Typography variant="subtitle1">Have a coupon?</Typography>
                <FormControl fullWidth>
                  <TextField
                    label="Coupon code"
                    variant="outlined"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    disabled={!!appliedCoupon} // Disable the text field if a coupon is applied
                  />
                  {appliedCoupon ? (
                    <div className="flex justify-between">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{ mt: 2 }}
                        onClick={applyCoupon}
                      >
                        Remove Coupon
                      </Button>
                      <Button
                        // variant="outlined"
                        color="primary"
                        size="small"
                        onClick={handleOpenTcModal}
                        sx={{ mt: 2 }}
                      >
                        “T&C”
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ mt: 2 }}
                      onClick={applyCoupon}
                    >
                      Apply
                    </Button>
                  )}
                </FormControl>
              </div>
            </Card>
            <Card sx={{ mt: 3 }}>
              <div style={{ padding: "16px" }}>
                {renderCouponInfo()}
                <div className="flex justify-between">
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold" }}
                  >
                    Total:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    ₹{calculateSubtotal().toFixed(2)}
                  </Typography>
                </div>
                <hr />
                <div sx={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src="/pay_methods_branding.png"
                    alt="Payment Methods"
                    className="mt-8"
                  />
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <UnauthorizedUserCart />
      )}
    </Container>
  )
}

export default Cart
