import React from "react"
import "./Research.css"
import CardCarousel from "./CardCarousel"
import { Link } from "react-router-dom"
export const Research = () => {
  return (
    <div
      className="research"
      style={{ backgroundColor: "#f9f8f0" }}
    >
      <div className="firstcol-research">
        <p>
          A program led by speech therapists, trusted by the parents, and
          <u> backed by extensive research.</u>
        </p>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to="https://www.researchgate.net/publication/51037307_The_Effectiveness_of_Parent-Implemented_Language_Interventions_A_Meta-Analysis#:~:text=The%20results%20indicate%20that%20parent,outcome%20measure%20and%20comparison%20group"
        >
          <button className="getstarted">Show me research</button>
        </Link>
      </div>
      <div className="secondcol-research">
        <CardCarousel />
      </div>
    </div>
  )
}
