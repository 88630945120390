// QuestionsPage.js
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
} from "@mui/material"
import questionsByAge from "./question.js"

const QuestionsPage = () => {
  const navigate = useNavigate()
  const { age } = useParams()
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [answer, setAnswer] = useState("")
  const [answers, setAnswers] = useState([])

  const handleNextQuestion = () => {
    const updatedAnswers = [...answers]
    updatedAnswers[currentQuestion] = answer
    setAnswers(updatedAnswers)

    const nextQuestion = currentQuestion + 1
    if (nextQuestion < questionsByAge[age].length) {
      setCurrentQuestion(nextQuestion)
      setAnswer("")
    } else {
      console.log("Answers:", updatedAnswers)
      navigate(`/screener/results/${age}`, {
        state: { answers: updatedAnswers },
      })
    }
  }

  const handlePreviousQuestion = () => {
    const prevQuestion = currentQuestion - 1
    setCurrentQuestion(prevQuestion)
    setAnswer(answers[prevQuestion] || "") // Restore the previous answer
  }

  const handleOptionSelect = (selectedOption) => {
    setAnswer(selectedOption)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      p={4}
      borderRadius={8}
      bgcolor="#f9f8f0"
      maxWidth={500}
      mx="auto"
      mt={4}
    >
      {currentQuestion > 0 && (
        <div
          onClick={handlePreviousQuestion}
          style={{ cursor: "pointer" }}
        >
          🔙 back
        </div>
      )}

      <Typography
        variant="h4"
        align="flext-start"
        mb={4}
        mt={5}
      >
        {questionsByAge[age][currentQuestion].question}
      </Typography>

      <FormControl component="fieldset">
        <RadioGroup
          value={answer}
          onChange={(e) => handleOptionSelect(e.target.value)}
        >
          {questionsByAge[age][currentQuestion].options.map((option) => (
            <Grid
              item
              xs={12}
              key={option}
              p={1}
            >
              <Box
                onClick={() => handleOptionSelect(option)}
                bgcolor={answer === option ? "#e0d2fe" : "#f0f0f0"}
                p={2}
                borderRadius={4}
                style={{ cursor: "pointer" }}
              >
                <FormControlLabel
                  value={option}
                  control={<Radio color="primary" />}
                  label={option}
                  style={{ pointerEvents: "none" }}
                  p={2}
                />
              </Box>
            </Grid>
          ))}
        </RadioGroup>
      </FormControl>

      {currentQuestion < questionsByAge[age].length - 1 && (
        <div
          className="next-button"
          onClick={handleNextQuestion}
        >
          Next
        </div>
      )}
      {currentQuestion === questionsByAge[age].length - 1 && (
        <div
          className="next-button"
          onClick={handleNextQuestion}
        >
          Finish
        </div>
      )}
    </Box>
  )
}

export default QuestionsPage
