import React, { useEffect, useState } from "react"
import {
  Box,
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
} from "@mui/material"
import axios from "axios"

const MyOrdersPage = () => {
  const [orders, setOrders] = useState("")
  const [expandedOrder, setExpandedOrder] = useState(null)

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get("api/my-orders", {
          headers: {
            authorisation: JSON.parse(localStorage.getItem("data")).token,
          },
        })
        setOrders(response.data.reverse()) // Reverse the orders array
      } catch (error) {
        console.error("Error fetching orders:", error)
      }
    }
    fetchOrders()
  }, [])

  const handleToggle = (orderId) => {
    setExpandedOrder((prevExpandedOrder) =>
      prevExpandedOrder === orderId ? null : orderId
    )
  }

  return (
    <Container
      maxWidth="md"
      sx={{ marginTop: 4 }}
    >
      <Typography
        variant="h4"
        gutterBottom
      >
        My Orders
      </Typography>
      {orders.length === 0 ? (
        <Typography variant="body1">
          You haven't placed any orders yet.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>Order ID</TableCell> */}
                <TableCell>Products</TableCell>
                <TableCell>Total Price</TableCell>
                {/* <TableCell>Currency</TableCell> */}
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <React.Fragment key={order._id}>
                  <TableRow
                    onClick={() => handleToggle(order._id)}
                    style={{ cursor: "pointer" }}
                  >
                    {/* <TableCell>{order.razorpayOrderId.split("_")[1]}</TableCell> */}
                    <TableCell>
                      <ul>
                        {order.products.map((product) => (
                          <li
                            key={product._id}
                            style={{ listStyle: "none" }}
                          >
                            <img
                              src={product.product.images[0].url}
                              alt={product.product.name}
                              style={{
                                width: "50px",
                                height: "50px",
                                marginRight: "10px",
                              }}
                            />
                            {product.product.name} - ({product.quantity}
                            {product.quantity > 1 ? "pcs" : "pc"})
                          </li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell>₹{order.amount}</TableCell>
                    {/* <TableCell>{order.currency}</TableCell> */}
                    <TableCell>{order.status}</TableCell>
                    <TableCell>
                      {new Date(order.createdAt).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Collapse
                        in={expandedOrder === order._id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                          >
                            {/* Shipping Address */}
                            Order Id:-
                            {order.razorpayOrderId.split("_")[1]}
                          </Typography>
                          <Typography variant="body1">
                            {/* Name: {order.shippingAddress?.name} */}
                          </Typography>
                          <Typography variant="body1">
                            Coupan:{order.couponCode}
                            Address: {order.shippingAddress.addressLine1},
                            {order.shippingAddress.addressLine2},
                            {order.shippingAddress.city},
                            {order.shippingAddress.state} -
                            {order.shippingAddress.pincode}
                          </Typography>
                          <Typography variant="body1">
                            Mobile: +{order.shippingAddress.mobile}
                          </Typography>
                          <div className="flex justify-between">
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold" }}
                            >
                              SubTotal:
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              ₹{order.amount.toFixed(2)}
                            </Typography>
                          </div>
                          <div className="flex justify-between">
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Discount:
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {/* - ₹{appliedCoupon.discountAmount.toFixed(2)} */}
                            </Typography>
                          </div>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  )
}

export default MyOrdersPage
