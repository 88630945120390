import React, { useState, useEffect } from "react"
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  MenuItem,
  IconButton,
} from "@mui/material"
import axios from "axios"
import DeleteIcon from "@mui/icons-material/Delete"
import { useNavigate } from "react-router-dom"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

const Checkout = ({ total, products, couponCode }) => {
  const [userAddresses, setUserAddresses] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    // Fetch user addresses when the component mounts
    fetchUserAddresses()
  }, [])
  const [formData, setFormData] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    mobile: "",
    pincode: "",
    city: "",
    state: "",
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handlePincodeChange = async (event) => {
    const newPincode = event.target.value
    setFormData((prevData) => ({ ...prevData, pincode: newPincode }))
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${newPincode}`
      )
      if (
        response.data &&
        response.data[0]?.Status === "Success" &&
        response.data[0]?.PostOffice?.length
      ) {
        const data = response.data[0].PostOffice[0]
        setFormData((prevData) => ({
          ...prevData,
          city: data.District,
          state: data.State,
        }))
      } else {
        setFormData((prevData) => ({ ...prevData, city: "", state: "" }))
      }
    } catch (error) {
      console.error("Error fetching data:", error)
      setFormData((prevData) => ({ ...prevData, city: "", state: "" }))
    }
  }

  const handleAddressSelect = (selectedAddress) => {
    // Extract relevant properties and convert them to strings
    const { name, addressLine1, addressLine2, mobile, pincode, city, state } =
      selectedAddress

    // Update form data
    setFormData({
      name: String(name),
      addressLine1: String(addressLine1),
      addressLine2: String(addressLine2),
      mobile: String(mobile),
      pincode: String(pincode),
      city: String(city),
      state: String(state),
    })
  }

  const fetchUserAddresses = async () => {
    try {
      // Fetch user addresses from your backend
      const response = await axios.get("api/get-address", {
        headers: {
          authorisation: JSON.parse(localStorage.getItem("data")).token,
        },
      })

      setUserAddresses(response.data.addresses)
    } catch (error) {
      console.error("Error fetching user addresses:", error)
    }
  }
  const handleDeleteAddress = async (addressId) => {
    console.log(addressId)
    try {
      await axios.delete(`api/delete-address/${addressId}`, {
        headers: {
          authorisation: JSON.parse(localStorage.getItem("data")).token,
        },
      })
      fetchUserAddresses()
    } catch (error) {
      console.error("Error deleting address:", error)
    }
  }

  const checkoutHandler = async () => {
    try {
      const productDetails = products.map((item) => ({
        product: item.product._id,
        quantity: item.quantity,
      }))

      const {
        data: { key },
      } = await axios.get("api/getkey")

      const {
        data: { order },
      } = await axios.post(
        "api/razorpay/payment",
        {
          amount: total,
          productDetails: productDetails,
          couponCode: couponCode,
        },

        {
          headers: {
            authorisation: JSON.parse(localStorage.getItem("data")).token,
          },
        }
      )
      const orderId = order.id
      const options = {
        key: key,
        amount: total,
        currency: "INR",
        name: "Simba Speaks",
        description: "Revolutionary Online Speech Therapy Platform",
        image: "/simba_speaks_logo.png",
        order_id: orderId,
        callback_url: `${process.env.REACT_APP_BASE_URL}api/verify-payment`,
        notes: {
          address: "Jamshedpur",
        },
        theme: {
          color: "#121212",
        },
      }
      const razor = new window.Razorpay(options)

      razor.open()
      const requestBody = {
        formData,
        orderId,
      }
      await axios.post("api/checkout-address", requestBody, {
        headers: {
          authorisation: JSON.parse(localStorage.getItem("data")).token,
        },
      })
      // Handle the response from the server (e.g., redirect to Razorpay checkout page)
    } catch (error) {
      // Handle any errors during the entire process
      console.error("Error during Razorpay payment:", error)
      // Redirect to an error page or show an error message
      navigate("/error")
    }
  }

  return (
    <Grid
      container
      justifyContent="center"
    >
      <Grid
        item
        xs={12}
        sm={8}
        md={9}
      >
        <Typography
          variant="h4"
          sx={{ textAlign: "left", mb: 3 }}
          gutterBottom
        >
          Checkout
        </Typography>
        <Paper
          elevation={3}
          style={{ padding: "16px" }}
        >
          {/* Shipping Information */}
          <Typography
            variant="h6"
            gutterBottom
          >
            Shipping Information
          </Typography>
          <TextField
            select
            label="Select Address"
            fullWidth
            margin="normal"
            value=""
            onChange={(e) => handleAddressSelect(e.target.value)}
          >
            {userAddresses.map((address) => (
              <MenuItem
                key={address._id}
                value={address}
              >
                {`${address.addressLine1}, ${address.city}, ${address.state} - ${address.pincode}`}
                <IconButton onClick={() => handleDeleteAddress(address._id)}>
                  <DeleteIcon />
                </IconButton>
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="name"
            label="Name"
            fullWidth
            margin="normal"
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            name="addressLine1"
            label="Address Line 1"
            fullWidth
            margin="normal"
            value={formData.addressLine1}
            onChange={handleInputChange}
          />
          <TextField
            name="addressLine2"
            label="Address Line 2"
            fullWidth
            margin="normal"
            value={formData.addressLine2}
            onChange={handleInputChange}
          />

          <PhoneInput
            name="mobile"
            placeholder="Enter mobile number"
            country={"in"}
            value={formData.mobile}
            onChange={(value) =>
              handleInputChange({ target: { name: "mobile", value } })
            }
            inputProps={{
              required: true,
            }}
          />
          <TextField
            name="pincode"
            label="Pincode"
            fullWidth
            margin="normal"
            value={formData.pincode}
            onChange={handlePincodeChange}
          />
          <TextField
            name="city"
            label="City"
            fullWidth
            margin="normal"
            value={formData.city}
          />
          <TextField
            name="state"
            label="State"
            fullWidth
            margin="normal"
            value={formData.state}
            disabled
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={checkoutHandler}
          >
            Place Order
          </Button>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Checkout
