import {
  Box,
  Typography,
  // Menu,
  // MenuItem,
  Button,
  IconButton,
} from "@mui/material"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import { useState, useContext, useEffect } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../context/AuthContext"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { useLogout } from "../Hooks/useLogout"
import axios from "axios"
import { successToast } from "../utils/toastConfig"
import { CartContext } from "../context/CartContext"

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openMenu, setOpenMenu] = useState(false)
  const [cartItemCount, setCartItemCount] = useState(0)
  const { state } = useContext(CartContext)
  // const open = Boolean(anchorEl)
  const { data } = useContext(AuthContext)
  const { logout } = useLogout()

  const handleLogout = () => {
    logout()
    successToast("Signed out Successfully!")
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenMenu(false)
  }

  const handleMenu = () => {
    setAnchorEl(null)
    setOpenMenu(!openMenu)
  }

  const updateCartItemCount = async () => {
    try {
      const response = await axios.get(`api/getCart`, {
        headers: {
          authorisation: JSON.parse(localStorage.getItem("data")).token,
        },
      })
      const cartItemData = response.data
      const itemCount = cartItemData.length

      setCartItemCount(itemCount)
    } catch (error) {
      console.error("Error fetching cart items:", error)
    }
  }

  useEffect(() => {
    updateCartItemCount()
  }, [state.cartItems])

  return (
    <>
      <Box
        className="logo-container"
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        pt={2}
        pb={1}
      >
        <IconButton
          aria-label=""
          sx={{ display: { md: "none" } }}
          onClick={handleMenu}
        >
          {openMenu ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Link
          to="/"
          style={{ textDecorationLine: "none" }}
        >
          <Box
            component="span"
            display="flex"
            alignItems="center"
            id="logo-container"
          >
            <img
              className="logo"
              src="/simba_speaks_logo.png"
              alt="simba_speaks_logo.png"
              srcSet=""
            />
            <Typography
              variant="h4"
              color="#A25C32"
              fontSize={{ xs: "20px", md: "32px" }}
              fontWeight={700}
            >
              Simba Speaks
            </Typography>
          </Box>
        </Link>
        <Box
          id="menu-container"
          gap={4}
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Box id="menu-container-child">
            <Link
              to="/program"
              style={{ textDecorationLine: "none" }}
            >
              <Button
                id="basic-button"
                sx={{
                  color: "#000000",
                  fontSize: "18px",
                  textTransform: "none",
                  fontFamily: "Lato",
                  fontWeight: "700",
                  fontStyle: "italic",
                }}
              >
                Programs
              </Button>
            </Link>
          </Box>
          <Box id="menu-container-child">
            <Link
              to="/products"
              style={{ textDecorationLine: "none" }}
            >
              <Button
                id="basic-button"
                sx={{
                  color: "#000000",
                  fontSize: "18px",
                  textDecoration: "none",
                  textTransform: "none",
                  fontFamily: "Lato",
                  fontWeight: "700",
                  fontStyle: "italic",
                }}
              >
                Products
              </Button>
            </Link>
          </Box>
          {/* <Box id="menu-container-child">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                color: "#000000",
                fontSize: "15px",
              }}
            >
              Community
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
          </Box> */}
          <Box id="menu-container-child">
            <Link
              to="/pricing"
              style={{ textDecorationLine: "none" }}
            >
              <Button
                id="basic-button"
                sx={{
                  color: "#000000",
                  fontSize: "18px",
                  textTransform: "none",
                  fontFamily: "Lato",
                  fontWeight: "700",
                  fontStyle: "italic",
                }}
              >
                Subscription
              </Button>
            </Link>
          </Box>
          <Box id="menu-container-child">
            <Link
              to="/about"
              style={{ textDecorationLine: "none" }}
            >
              <Button
                id="basic-button"
                sx={{
                  color: "#000000",
                  fontSize: "18px",
                  textTransform: "none",
                  fontFamily: "Lato",
                  fontWeight: "700",
                  fontStyle: "italic",
                }}
              >
                About Us
              </Button>
            </Link>
          </Box>
        </Box>
        <Link
          to="/cart"
          style={{ textDecoration: "none" }}
        >
          <IconButton
            id="cart-button"
            sx={{
              color: "#000000",
              fontSize: "15px",
            }}
          >
            <ShoppingCartIcon />
            <span className="cart-item-count">{cartItemCount}</span>
          </IconButton>
        </Link>
        {data && (
          <Box
            display="flex"
            alignItems="center"
            gap={4}
          >
            <Box id="menu-container-child">
              <Link
                to="/profile/myprofile"
                style={{ textDecorationLine: "none" }}
              >
                <Button
                  id="basic-button"
                  sx={{
                    color: "#000000",
                    fontSize: "15px",
                  }}
                >
                  <AccountCircleIcon />
                </Button>
              </Link>
            </Box>
            <Link
              to="/"
              style={{ textDecorationLine: "none" }}
            >
              <button
                onClick={handleLogout}
                sx={{
                  color: "#4E93D6",
                  fontWeight: "700",
                  display: { xs: "none", md: "flex" },
                }}
                className="rounded-full font-mono bg-[#ff9248] w-fit text-white  text-sm md:text-base lg:text-lg xl:text-xl  hover:bg-white  hover:text-[#ff9248] px-4 py-2.5 h-12"
              >
                Log Out
              </button>
            </Link>
          </Box>
        )}
        {!data && (
          <Box
            display="flex"
            gap={1}
          >
            <Link
              to="/auth/login"
              style={{ textDecorationLine: "none" }}
            >
              <button className="rounded-full font-mono bg-[#ff9248] w-fit text-white  text-sm md:text-base lg:text-lg xl:text-xl  hover:bg-white  hover:text-[#ff9248] px-4 py-2.5 h-12">
                Sign In
              </button>
            </Link>
            {/* <Link to="/auth/signup">
              <button className="rounded-full font-mono hidden bg-[#ff9248] w-fit text-white  text-sm md:text-base lg:text-lg xl:text-xl  hover:bg-white  hover:text-[#ff9248] px-4 py-2.5 h-12 sm:block ">
                Sign Up
              </button>
            </Link> */}
          </Box>
        )}
      </Box>
      {/* Mobile view navbar */}
      <Box>
        <Box
          id="menu-container"
          gap={4}
          flexDirection="column"
          alignItems="center"
          sx={{
            display: {
              xs: openMenu ? "flex" : "none",
              md: "none",
            },
          }}
        >
          <Box id="menu-container-child">
            <Link
              to="/program"
              style={{ textDecoration: "none" }}
              onClick={handleClose}
            >
              <Button
                id="basic-button"
                sx={{
                  color: "#000000",
                  fontSize: "18px",
                  textTransform: "none",
                  fontFamily: "Lato",
                  fontWeight: "700",
                  fontStyle: "italic",
                }}
              >
                Programs
              </Button>
            </Link>
          </Box>
          {/* <Box id="menu-container-child">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                color: "#000000",
                fontSize: "15px",
              }}
            >
              Products
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
          </Box> */}
          <Box id="menu-container-child">
            <Link
              to="/products"
              style={{ textDecoration: "none" }}
              onClick={handleClose}
            >
              <Button
                id="basic-button"
                sx={{
                  color: "#000000",
                  fontSize: "18px",
                  textTransform: "none",
                  fontFamily: "Lato",
                  fontWeight: "700",
                  fontStyle: "italic",
                }}
              >
                Products
              </Button>
            </Link>
          </Box>
          <Box id="menu-container-child">
            <Link
              to="/pricing"
              style={{ textDecoration: "none" }}
              onClick={handleClose}
            >
              <Button
                id="basic-button"
                sx={{
                  color: "#000000",
                  fontSize: "18px",
                  textTransform: "none",
                  fontFamily: "Lato",
                  fontWeight: "700",
                  fontStyle: "italic",
                }}
              >
                Subscription
              </Button>
            </Link>
          </Box>
          {/* <Box id="menu-container-child">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                color: "#000000",
                fontSize: "15px",
              }}
            >
              About Us
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
          </Box> */}
          <Box id="menu-container-child">
            <Link
              to="/about"
              style={{ textDecoration: "none" }}
              onClick={handleClose}
            >
              <Button
                id="basic-button"
                sx={{
                  color: "#000000",
                  fontSize: "18px",
                  textTransform: "none",
                  fontFamily: "Lato",
                  fontWeight: "700",
                  fontStyle: "italic",
                }}
              >
                About Us
              </Button>
            </Link>
          </Box>
          {data ? (
            <Link
              to="/"
              style={{ textDecorationLine: "none" }}
            >
              <button
                onClick={() => {
                  logout()
                  handleClose() // Close the menu after logging out
                }}
                sx={{ color: "#4E93D6", fontWeight: "700" }}
                className="rounded-full font-mono bg-[#ff9248] w-fit text-white  text-sm md:text-base lg:text-lg xl:text-xl  hover:bg-white  hover:text-[#ff9248] px-4 py-2.5 h-12"
              >
                Log Out
              </button>
            </Link>
          ) : (
            <Link
              to="/auth/login"
              style={{ textDecoration: "none" }}
              onClick={handleClose}
            >
              <button
                sx={{ color: "#4E93D6", fontWeight: "700" }}
                className="rounded-full font-mono bg-[#ffb38a] w-fit text-white text-sm md:text-base lg:text-lg xl:text-xl  hover:bg-white  hover:text-[#ffb38a] px-4 py-2.5 h-12"
              >
                Log In
              </button>
            </Link>
          )}
        </Box>
      </Box>
    </>
  )
}

export default Navbar
