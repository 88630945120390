import {Box, Typography} from '@mui/material'

const Shipping = () => {
    return ( 
        <Box display="flex" flexDirection="column" gap={2} sx={{width:{xs:"80vw", sm:"40vw"}}}>
            <Typography variant="h5" fontWeight={600}>Shipping & Delivery Policy</Typography>
            <Typography variant="p" color="#6A788C" fontWeight={500}>Last updated on Oct 26th 2023</Typography>
            <Typography variant="p" color="#6A788C" fontSize="15px">Shipping is not applicable for business.</Typography>
        </Box>
     );
}
 
export default Shipping;