import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import axios from "axios"

const EmailVerify = () => {
  const [verificationStatus, setVerificationStatus] = useState("loading")
  const [verificationMessage, setVerificationMessage] = useState("")
  const param = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const url = `api/auth/verify/${param.id}/verify/${param.token}`
        const { data } = await axios.get(url)
        setVerificationStatus(data.success ? "success" : "error")
        setVerificationMessage(data.message || "")
      } catch (error) {
        console.error(error)
        setVerificationStatus("error")
        setVerificationMessage("An unexpected error occurred.")
      }
    }

    // Only run the verification logic on component mount
    if (verificationStatus === "loading") {
      verifyEmailUrl()
    }
  }, [param, verificationStatus, navigate])

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      {verificationStatus === "success" ? (
        <div className="text-center">
          <img
            src="/success.png"
            alt="success_img"
            className="mb-4 mx-auto"
            style={{ width: "100px", height: "100px" }}
          />
          <h1 className="text-3xl font-bold text-green-600">
            Email verified successfully
          </h1>
          <p className="text-gray-600 mt-2">{verificationMessage}</p>
          <button
            onClick={() => navigate("/auth/login")}
            className="mt-4 bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-full focus:outline-none"
          >
            Login
          </button>
        </div>
      ) : verificationStatus === "error" ? (
        <div className="text-center">
          <h1 className="text-3xl font-bold text-red-600">
            Verification Failed
          </h1>
          <p className="text-gray-600 mt-2">{verificationMessage}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}

export default EmailVerify
