import { useContext } from "react"
import { AuthContext } from "../context/AuthContext"
import { Navigate } from "react-router-dom"

const ProtectedRoute = ({ children }) => {
  const { data } = useContext(AuthContext)
  const access = data ? (
    children
  ) : (
    <Navigate
      to="/auth/login"
      replace
    />
  )
  return access
}

export default ProtectedRoute
