import React, { useState, useContext, useEffect } from "react"
import { FaStar } from "react-icons/fa"
import { AuthContext } from "../context/AuthContext"
import { successToast, errorToast } from "../utils/toastConfig"
import axios from "axios"
import { Rating } from "@mui/material"

const colors = {
  orange: "#fbbf24",
  grey: "#a9a9a9",
}

const TakeReview = ({ productId }) => {
  const [currentValue, setCurrentValue] = useState(0)
  const [hoverValue, setHoverValue] = useState(undefined)
  const [reviewText, setReviewText] = useState("")
  const [userReview, setUserReview] = useState(null)
  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const { data } = useContext(AuthContext)
  const stars = Array(5).fill(0)

  useEffect(() => {
    const fetchUserReview = async () => {
      if (!data) return

      try {
        const response = await axios.get(`/api/reviewed/${productId}`, {
          headers: {
            authorisation: JSON.parse(localStorage.getItem("data")).token,
          },
        })

        setUserReview(response.data.review)
      } catch (error) {
        console.error("Error fetching user review:", error)
      }
    }

    fetchUserReview()
  }, [data, productId])

  const handleClick = (value) => {
    setCurrentValue(value)
  }

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue)
  }

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }

  const handleReviewTextChange = (e) => {
    setReviewText(e.target.value)
  }

  const handleSubmit = async () => {
    if (!data) {
      console.log("User not authenticated")
      return
    }

    try {
      const response = await axios.post(
        "/api/add-review",
        {
          product: productId,
          rating: currentValue,
          comment: reviewText,
        },
        {
          headers: {
            authorisation: JSON.parse(localStorage.getItem("data")).token,
          },
        }
      )

      if (response.status === 201 || response.status === 200) {
        successToast("Review submitted successfully")
        setCurrentValue(0)
        setHoverValue(undefined)
        setReviewText("")
        setUserReview(response.data.review)
      } else {
        errorToast("Failed to submit review add rating also")
      }
    } catch (error) {
      errorToast("Failed to submit review", error)
    }
  }

  const handleUpdateButtonClick = () => {
    setIsUpdateMode(!isUpdateMode)
    setReviewText(userReview ? userReview.comment : "")
  }

  return (
    <div>
      {!data ? null : userReview && isUpdateMode ? (
        <div className="flex flex-col items-center mt-10">
          <h2 className="text-2xl">Update Review:</h2>
          <div className="flex flex-row">
            {stars.map((_, index) => (
              <FaStar
                key={index}
                size={24}
                onClick={() => handleClick(index + 1)}
                onMouseOver={() => handleMouseOver(index + 1)}
                onMouseLeave={handleMouseLeave}
                color={
                  (hoverValue || currentValue) > index
                    ? colors.orange
                    : colors.grey
                }
                className="mr-2 cursor-pointer"
              />
            ))}
          </div>
          <textarea
            placeholder="Update your experience..."
            className="border border-gray-300 p-2 m-4 min-h-32 rounded-md w-full bg-gray-100"
            value={reviewText}
            onChange={handleReviewTextChange}
          />

          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            onClick={handleSubmit}
            disabled={!currentValue || !reviewText.trim()}
          >
            Update
          </button>
        </div>
      ) : (
        <div className="flex flex-col items-center mt-10">
          {userReview ? (
            // User has reviewed: Display review and update button
            <>
              <h2 className="text-2xl">Your Review:</h2>
              <div className="flex items-center gap-3 mb-4">
                <Rating
                  name="read-only"
                  value={userReview.rating}
                  size="large"
                  readOnly
                />
              </div>
              <div class="flex sm:items-center flex-col  justify-between gap-5 mb-4">
                <div class="flex items-center gap-3">
                  <img
                    src="https://pagedone.io/asset/uploads/1704349572.png"
                    alt="John "
                    class="w-8 h-8"
                  />
                </div>
                <p class="font-normal text-lg leading-8 text-gray-400">
                  {new Date(userReview.createdAt).toLocaleDateString()}
                </p>
                <p class="font-normal text-lg leading-8 text-gray-400 max-xl:text-justify">
                  {userReview.comment}
                </p>
              </div>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={handleUpdateButtonClick}
              >
                {isUpdateMode ? "Cancel Update" : "Update Review"}
              </button>
            </>
          ) : (
            // User hasn't reviewed: Display input box to submit review
            <>
              <h2 className="text-2xl">Create Review:</h2>
              <div className="flex flex-row">
                {stars.map((_, index) => (
                  <FaStar
                    key={index}
                    size={24}
                    onClick={() => handleClick(index + 1)}
                    onMouseOver={() => handleMouseOver(index + 1)}
                    onMouseLeave={handleMouseLeave}
                    color={
                      (hoverValue || currentValue) > index
                        ? colors.orange
                        : colors.grey
                    }
                    className="mr-2 cursor-pointer"
                  />
                ))}
              </div>
              <textarea
                placeholder="What's your experience?"
                className="border border-gray-300 p-2 m-4 min-h-32 rounded-md w-full bg-gray-100"
                value={reviewText}
                onChange={handleReviewTextChange}
              />

              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default TakeReview
