import React from "react"
import "./UserCriteria.css"
export const UserCriteria = () => {
  return (
    <>
      <div
        className="userCriteria"
        style={{ backgroundColor: "#f9f8f0" }}
      >
        <h2>Any age is the right age to start Therapy.</h2>
        <div className="userCriterioCard">
          <div>
            <img
              src="/program(1).png"
              alt="img"
            />
            <span>
              Kids learn best from those they adore the most—you! In your
              toddler’s sessions, you’ll pick up practical and easy-to-apply
              strategies to use at home.
            </span>
          </div>
          <div>
            <img
              src="/program(3).png"
              alt="img"
            />
            <span>
              Engage in enjoyable, interactive virtual sessions with your child,
              where your speech therapist will provide tips that you can
              effortlessly incorporate into your daily activities.
            </span>
          </div>
          <div>
            <img
              src="/program(2).png"
              alt="img"
            />
            <span>
              While children may interact more independently , you'll be
              continually updated with home activities and progress reports.
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
