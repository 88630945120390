import React from "react"

export const GlobalContext = React.createContext()

export const GlobalStorage = ({ children }) => {
  const [step, setStep] = React.useState(1)
  const [formData, setFormData] = React.useState({
    name: "",
    age: "",
    gender: "",
    respondsToName: "",
    makesEyeContact: "",
    followsInstructions: "",
    sustainableAttention: "",
    enjoysPlayingWithPeers: "",
    aloofPlay: "",
    preDiagnosis: "",
    motorDifficulty: "",
    initialComplaint: "",
  })

  return (
    <GlobalContext.Provider
      value={{
        step,
        setStep,
        formData,
        setFormData,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
