import {
  Box,
  Typography,
  Stack,
  TextField,
  InputLabel,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import GoogleIcon from "@mui/icons-material/Google"
import { useState } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import { useContext } from "react"
import { AuthContext } from "../context/AuthContext"
import { useNavigate } from "react-router-dom"
import { successToast, errorToast } from "../utils/toastConfig"
import { auth, googleProvider, signInWithPopup } from "../utils/firebase"

const LoginCard = () => {
  const [email, setEmail] = useState("")
  const [pwd, setPwd] = useState("")
  const [err, setErr] = useState(false)
  const [errmsg, setErrmsg] = useState("")
  const [values, setValues] = useState({
    showPassword: false,
  })
  const { dispatch } = useContext(AuthContext)
  //password visibility
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const navigate = useNavigate()

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleSubmit = async () => {
    if (!email || !pwd) {
      setErr(true)
      setErrmsg("All fields must be filled!")
      errorToast("All fields must be filled!")
      return
    }
    setErr(false)
    try {
      const { data } = await axios.post("api/auth/login", {
        email: email,
        password: pwd,
      })
      if (!data.success) {
        setErr(true)
        // setErrmsg(data.message)
        errorToast(data.message)
      } else {
        successToast("Signed in Successfully!")
        localStorage.setItem("data", JSON.stringify(data))
        dispatch({
          type: "LOGIN",
          payload: data,
        })
        navigate("/")
      }
    } catch (error) {
      errorToast("An unexpected error occurred. Please try again later.")
    }
  }

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider)
      const { user } = result
      const response = await axios.post("/api/auth/firebase-login", {
        idToken: await user.getIdToken(),
        displayName: user.displayName,
      })

      const { success, message, token } = response.data

      if (success) {
        successToast("Signed in Successfully!")
        localStorage.setItem("data", JSON.stringify({ token }))
        dispatch({
          type: "LOGIN",
          payload: { token },
        })
        navigate("/")
      } else {
        setErr(true)
        setErrmsg(message)
        errorToast(message)
      }
    } catch (error) {
      console.error("Firebase OAuth error:", error)
    }
  }

  return (
    <Box
      height="max-content"
      ml={{ xs: 0, md: 30 }}
      padding="20px 0px"
      bgcolor="white"
      width={{ xs: "100vw", md: "25vw" }}
      border={{ xs: 0, md: "1px solid #999999" }}
      borderRadius={2}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <img
          className="logo_tagline"
          src="/logo_tagline.png"
          alt=""
          srcSet=""
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h3"
            fontWeight={700}
            fontSize="25px"
          >
            Log in to your account
          </Typography>
          <Typography
            variant="p"
            color="#3d3d3d"
            fontSize="16px"
          >
            Please enter your details
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          gap={1}
        >
          <Box>
            <InputLabel sx={{ color: "#000000" }}>Email</InputLabel>
            <TextField
              hiddenLabel
              placeholder="Enter Your Email"
              size="small"
              style={{ width: "260px" }}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </Box>
          <Stack sx={{ marginTop: "10px", alignItems: "start" }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="pwd"
              placeholder="Enter your password"
              type={values.showPassword ? "text" : "password"}
              value={pwd}
              size="small"
              onChange={(e) => {
                setPwd(e.target.value)
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <Link to="/auth/forgotpassword">
              <Button
                sx={{ fontSize: "12px", fontWeight: "700", color: "#4e93d6" }}
              >
                Forgot Password
              </Button>
            </Link>
          </Stack>
          {err && (
            <Typography
              variant="p"
              color="red"
            >
              {/* {errmsg} */}
            </Typography>
          )}
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
          >
            Sign In
          </Button>
          <Typography
            variant="p"
            fontSize="12px"
          >
            -------------------------- OR --------------------------
          </Typography>
          <Button
            startIcon={<GoogleIcon />}
            variant="outlined"
            fullWidth
            sx={{
              fontSize: "12px",
              fontWeight: "700",
              color: "#000000",
              border: "1px solid black",
            }}
            onClick={signInWithGoogle}
          >
            Sign In with Google
          </Button>
        </Box>
        <Typography
          variant="p"
          color="#3d3d3d"
          fontSize="14px"
        >
          Don't have an account?{" "}
          <Link to="/auth/signup">
            <Button size="small">Sign Up</Button>
          </Link>
        </Typography>
      </Stack>
    </Box>
  )
}

export default LoginCard
