import { Box, Container } from "@mui/material"
import ProfileSection from "../Components/Profile/ProfileSection"
import { Outlet } from "react-router-dom"

const Account = () => {
  return (
    <Container>
      <Box
        mt={5}
        pb={3}
        display="flex"
        justifyContent="center"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "center", sm: "start" },
        }}
      >
        <ProfileSection />
        <Outlet />
      </Box>
    </Container>
  )
}

export default Account
