import React, { useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import {
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import questionsByAge from "./question.js"
import DoneIcon from "@mui/icons-material/Done"
import "./screener.css"

const ScreenerResult = () => {
  const location = useLocation()

  const { answers } = location.state
  const { age } = useParams()
  const [selectedQuestion, setSelectedQuestion] = useState(null)

  const getResultMessage = () => {
    if (isOnTrack) {
      return "Your answer tells your child is meeting communication milestones typical for their age. If you still have questions about your child's development, schedule a consultation."
    } else {
      return "Your answer tells us that your child might not be meeting communication milestones typical for their age. Please schedule a consultation."
    }
  }
  const getResultBanner = () => {
    if (isOnTrack) {
      return "Based on your answers, your child is on track with their speech and language development."
    } else {
      return "Based on your answer, a speech & Language is recommended for your child. "
    }
  }

  const handleQuestionChange = (index) => {
    setSelectedQuestion((prev) => (prev === index ? null : index))
  }

  const isOnTrack = answers.every((ans) => ans === "Yes" || ans === "Always")

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      p={4}
      maxWidth={600}
      mx="auto"
      minHeight="90vh"
    >
      <Typography
        variant="h5"
        mb={2}
        fontWeight="700"
      >
        {getResultBanner()}
      </Typography>
      <Typography
        variant="body1"
        mb={4}
        fontWeight="600"
      >
        {getResultMessage()}
      </Typography>
      <Box
        bgcolor="#f9f8f0"
        padding={2}
        borderRadius={8}
        width="90%"
      >
        <Typography
          variant="body1"
          gutterBottom
        >
          Development Areas
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          fontWeight="700"
        >
          Language
        </Typography>
        <Typography>
          {isOnTrack ? (
            <Typography
              display="inline-flex"
              bgcolor="#dafae5"
              borderRadius={5}
              padding={0.4}
            >
              <DoneIcon />
              ON TRACK
            </Typography>
          ) : (
            <Typography
              display="inline-flex"
              bgcolor="#f6fdc2"
              borderRadius={5}
              padding={0.4}
            >
              ⚡SUPPORT NEEDED
            </Typography>
          )}
        </Typography>
        <hr style={{ width: "100%", margin: "8px 0" }} />
        <Typography
          variant="h5"
          gutterBottom
          fontWeight="700"
        >
          Speech
        </Typography>
        {isOnTrack ? (
          <Typography
            display="inline-flex"
            bgcolor="#dafae5"
            borderRadius={5}
            padding={0.4}
          >
            <DoneIcon />
            ON TRACK
          </Typography>
        ) : (
          <Typography
            display="inline-flex"
            bgcolor="#f6fdc2"
            borderRadius={5}
            padding={0.4}
          >
            ⚡SUPPORT NEEDED
          </Typography>
        )}
        <hr style={{ width: "100%", margin: "8px 0" }} />
      </Box>

      <Typography
        variant="h4"
        mb={2}
        fontWeight="500"
      >
        Schedule a 30-minute consultation!
        <Typography
          variant="body1"
          mb={4}
        >
          Discuss your screener results with one of our licensed speech
          therapists and ask any questions. The speech therapist can help you
          schedule an evaluation to specifically address your child’s needs.
          Your child does not have to be present for the consultation.
        </Typography>
      </Typography>

      <Link to="/products/6680e7c18e43ef178d23edcc">
        <div className="next-button">Schedule your Consultation</div>
      </Link>
      <img
        src="/screener.png"
        alt="screener"
        style={{ width: "100%", height: "auto" }}
      />
      <Box
        bgcolor="#f9f8f0"
        padding={2}
        borderRadius={8}
      >
        <Typography
          variant="h4"
          mb={2}
          fontWeight="700"
        >
          Let’s take a closer look at your answers
        </Typography>
        <Typography
          variant="body1"
          mb={2}
          fontWeight="700"
        >
          Review your answers and learn how they compare to the communication
          milestones typical for your child’s age.
        </Typography>
        <Grid
          container
          spacing={2}
        >
          {questionsByAge[age] &&
            questionsByAge[age].map((q, index) => (
              <Grid
                item
                xs={12}
                key={index}
              >
                <Accordion
                  expanded={selectedQuestion === index}
                  onChange={() => handleQuestionChange(index)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                      <Typography variant="body1">
                        {answers[index] === "No" ||
                        answers[index] === "Sometimes" ||
                        answers[index] === "Never"
                          ? "⚡SUPPORT NEEDED"
                          : ""}
                      </Typography>
                      {q.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body2"
                      style={{ marginTop: 8 }}
                    >
                      {q.description}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
        </Grid>
      </Box>
      <Typography
        variant="h4"
        mb={2}
        mt={4}
        fontWeight="700"
      >
        Don’t wait to take the next step
      </Typography>
      <img
        src="/screener-result.png"
        alt=""
        style={{ width: "100%", height: "auto" }}
      />
      <Typography
        variant="h6"
        mt={4}
        mb={2}
        fontWeight="700"
      >
        Early life development resembles a stairway.
      </Typography>
      <Typography
        variant="body1"
        mb={4}
      >
        It's helpful to visualize a staircase while considering childhood
        growth. You don't leap up to the final step when climbing a staircase.
        Until you reach the top, you proceed one step at a time in a sequential
        manner.
      </Typography>
      <Typography
        variant="body1"
        mb={4}
      >
        This also applies to the growth of your child. As your child gets older,
        a number of skills linked to feeding and swallowing develop in a certain
        order. Children cannot advance to the next milestone until they have
        completed each one. Delaying starting speech therapy might make your
        child's developmental delay worse and impact future skills if they are
        not reaching their milestones.
      </Typography>
      <Link to="/products">
        <div className="next-button">Schedule your Consultation</div>
      </Link>
    </Box>
  )
}

export default ScreenerResult
