import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material"

const AgeSelectionPage = () => {
  const navigate = useNavigate()
  const [selectedAge, setSelectedAge] = useState("")

  const handleAgeSelection = (age) => {
    setSelectedAge(age)
  }

  const handleNext = () => {
    if (selectedAge) {
      navigate(`/screener/questions/${selectedAge}`)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      p={4}
      borderRadius={8}
      bgcolor="#f9f8f0"
      maxWidth={500}
      mx="auto"
      mt={4}
    >
      <Typography
        variant="h4"
        align="flext-start"
        mb={4}
        mt={5}
      >
        Choose your age:
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          value={selectedAge}
          onChange={(e) => handleAgeSelection(e.target.value)}
        >
          {[
            { value: "1", label: "1.1 to 1.6 years" },
            { value: "2", label: "1.7 to 2 years" },
            { value: "3", label: "3 years" },
            { value: "4", label: "4 years" },
            { value: "5", label: "5 to 7 years" },
          ].map((age) => (
            <Box
              p={1}
              key={age.value}
            >
              <Box
                key={age}
                onClick={() => handleAgeSelection(age.value)}
                p={2}
                borderRadius={4}
                bgcolor={selectedAge === age.value ? "#e0d2fe" : "#f0f0f0"}
                style={{ cursor: "pointer" }}
              >
                <FormControlLabel
                  value={age.value}
                  control={<Radio color="primary" />}
                  label={`${age.label}`}
                  style={{ pointerEvents: "none" }}
                />
              </Box>
            </Box>
          ))}
        </RadioGroup>
      </FormControl>

      <div
        className="next-button"
        onClick={handleNext}
      >
        Next
      </div>
    </Box>
  )
}

export default AgeSelectionPage
