import React from "react"
import {
  Box,
  Typography,
  Button,
  Container,
  Paper,
  useTheme,
  useMediaQuery,
  Link,
} from "@mui/material"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { Link as RouterLink } from "react-router-dom"

const RetryPaymentPage = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "80vh",
      }}
    >
      <Paper
        elevation={3}
        sx={{ padding: 4, width: "100%" }}
      >
        <Box textAlign="center">
          <ErrorOutlineIcon
            color="error"
            sx={{ fontSize: isSmallScreen ? 80 : 120 }}
          />
          <Typography
            variant="h4"
            component="div"
            gutterBottom
          >
            Payment Failed
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            paragraph
          >
            There was an issue processing your payment. Please try again.
          </Typography>
        </Box>
        <Box
          textAlign="center"
          mt={4}
        >
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/cart"
          >
            Retry Payment
          </Button>
        </Box>
        <Box
          textAlign="center"
          mt={2}
        >
          <Typography
            variant="body2"
            color="textSecondary"
          >
            or{" "}
            <Link
              component={RouterLink}
              to="/cart"
            >
              return to cart
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Container>
  )
}

export default RetryPaymentPage
