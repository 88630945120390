import React from "react"
import "./Banner.css"
import { Link } from "react-router-dom"

export const Banner = () => {
  return (
    <>
      <div className="banner">
        <div className="firstPart">
          <p className="frstPart-head">
            Assist your child in letting the world see who they are.
          </p>
          <p className="frstPart-head-dis">
            For live, one-on-one online sessions, collaborate with a speech
            therapist and get round-the-clock assistance.
          </p>
          <Link to="/products">
            <div className="frstPart-button cursor-pointer">Get Started</div>
          </Link>
        </div>
        <img
          src="/program-background.png"
          alt=""
          className="background-banner"
        />

        <img
          src="/program-kids.png"
          alt=""
          className="secondPart"
        />
      </div>
    </>
  )
}
