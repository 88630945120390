import { Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import '../../Styles/policysection.css'

const PolicySection = () => {
    const {pathname} = useLocation()
    return ( 
        <Box mb={5} display="flex" flexDirection="column" gap={4} sx={{width:{xs:"80vw", sm:"20vw"}}}>
            <Link to="/policy/terms"><Typography className={(pathname === '/policy/terms') ? 'active' : ''} variant="p" color="#6A788C" sx={{fontSize:{xs:"15px", sm:"18px"}}}>Terms & Conditions</Typography></Link>
            <Link to="/policy/contact"><Typography className={(pathname === '/policy/contact') ? 'active' : ''} variant="p" color="#6A788C" sx={{fontSize:{xs:"15px", sm:"18px"}}}>Contact Us</Typography></Link>
            <Link to="/policy/refund"><Typography className={(pathname === '/policy/refund') ? 'active' : ''} variant="p" color="#6A788C" sx={{fontSize:{xs:"15px", sm:"18px"}}}>Cancellation and Refund Policy</Typography></Link>
            <Link to="/policy/privacy"><Typography className={(pathname === '/policy/privacy') ? 'active' : ''} variant="p" color="#6A788C" sx={{fontSize:{xs:"15px", sm:"18px"}}}>Privacy Policy</Typography></Link>
            <Link to="/policy/shipping"><Typography className={(pathname === '/policy/shipping') ? 'active' : ''} variant="p" color="#6A788C" sx={{fontSize:{xs:"15px", sm:"18px"}}}>Shipping and Delivery Policy</Typography></Link>
        </Box>
     );
}
 
export default PolicySection;