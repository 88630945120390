import { Box, Typography } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import "../../Styles/policysection.css"

const ProfileSection = () => {
  const { pathname } = useLocation()
  return (
    <Box
      mb={5}
      display="flex"
      flexDirection="column"
      gap={4}
      sx={{ width: { xs: "80vw", sm: "20vw" } }}
    >
      <Link to="/profile/myprofile">
        <Typography
          className={pathname === "/profile/myprofile" ? "active" : ""}
          variant="p"
          color="#6A788C"
          sx={{ fontSize: { xs: "15px", sm: "18px" } }}
        >
          My Profile
        </Typography>
      </Link>
      <Link to="/profile/childprofile">
        <Typography
          className={pathname === "/profile/childprofile" ? "active" : ""}
          variant="p"
          color="#6A788C"
          sx={{ fontSize: { xs: "15px", sm: "18px" } }}
        >
          Child Profile
        </Typography>
      </Link>
      <Link to="/profile/myorder">
        <Typography
          className={pathname === "/profile/myorder" ? "active" : ""}
          variant="p"
          color="#6A788C"
          sx={{ fontSize: { xs: "15px", sm: "18px" } }}
        >
          My Order
        </Typography>
      </Link>
      <Link to="/profile/subscription">
        <Typography
          className={pathname === "/profile/subscription" ? "active" : ""}
          variant="p"
          color="#6A788C"
          sx={{ fontSize: { xs: "15px", sm: "18px" } }}
        >
          Subscription
        </Typography>
      </Link>
    </Box>
  )
}

export default ProfileSection
