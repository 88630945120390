import React, { useState } from "react"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import data from "./data.json"
import { Link } from "react-router-dom"

const AccordionComponent = () => {
  const [expanded, setExpanded] = useState(null)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null)
  }

  return (
    <div style={{ backgroundColor: "#f9f8f0", marginTop: "3rem" }}>
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        bgcolor="#f9f8f0"
        alignItems="center"
        gap={2}
      >
        <Typography
          variant="h3"
          mt={4}
          mb={2}
          fontWeight="700"
        >
          Any questions?
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: { xs: "95vw", md: "60vw" } }}
          gap={2}
        >
          {data.map((item, index) => (
            <Accordion
              key={index}
              elevation={0}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{
                padding: "20px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
              }}
            >
              <AccordionSummary
                expandIcon=<ExpandMoreIcon
                  sx={{
                    backgroundColor: "#ffb38a",
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                    "@media (min-width: 600px)": {
                      width: "3rem",
                      minWidth: "3rem",
                      height: "3rem",
                    },
                  }}
                />
                aria-label="Expand"
                aria-controls="1a-content"
                id="1a-header"
              >
                <Typography fontWeight={600}>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>{item.answer}</AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
      <Box
        mt={8}
        mx={0.5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        backgroundColor="white"
      >
        <Typography
          variant="h4"
          mb={2}
          mt={4}
          mx={2}
          fontWeight="700"
          maxWidth="sm"
        >
          Watch learning jump (leap! spring! hop!) from your sessions into the
          real world.
        </Typography>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to="https://www.researchgate.net/publication/51037307_The_Effectiveness_of_Parent-Implemented_Language_Interventions_A_Meta-Analysis#:~:text=The%20results%20indicate%20that%20parent,outcome%20measure%20and%20comparison%20group"
        >
          <button className="getstarted">Show me research</button>
        </Link>
        <img
          src="/program_footer.png"
          alt=""
          style={{ width: "100%", height: "auto" }}
        />
      </Box>
    </div>
  )
}

export default AccordionComponent
