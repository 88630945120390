import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { ExpandMore } from "@mui/icons-material"
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import Footer from "./Footer"
import { AuthContext } from "../context/AuthContext"
import { useNavigate } from "react-router-dom"
import { successToast, errorToast } from "../utils/toastConfig"
import Axios from "axios"
import ProductReview from "./ProductReview"
import TakeReview from "./TakeReview"
import { CartContext } from "../context/CartContext"

const ProductDetail = () => {
  const [addedToCart, setAddedToCart] = useState(false)
  const { data } = useContext(AuthContext)
  const { productId } = useParams()
  const [productData, setProductData] = useState(null)
  const navigate = useNavigate()
  const { dispatch } = useContext(CartContext)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/products/${productId}`)
        setProductData(response.data)
      } catch (error) {
        console.error("Error fetching product data:", error)
      }
    }

    fetchData()
  }, [productId])

  if (!productData) {
    return <p>Loading...</p>
  }

  const addToCart = () => {
    if (!data || !data?.token) {
      navigate("/cart")
      return
    }

    Axios.post(
      "api/addcart",
      {
        productId,
        quantity: 1,
      },
      {
        headers: {
          authorisation: JSON.parse(localStorage.getItem("data")).token,
        },
      }
    )
      .then((response) => {
        successToast("Item added to cart successfully!")
        dispatch({ type: "SET_CART", payload: response.data })
        setAddedToCart(true)
      })
      .catch((error) => {
        console.error("Error adding to cart:", error)
        errorToast("Failed to add item to cart. Please try again later.")
      })
  }
  // const formattedCourseOutline = productData.courseOutline[0]
  //   .split(",\n")
  //   .map((point) => point.trim())

  return (
    <>
      <div className="flex  gap-8 bg-[#f5f9fb] md:flex-row flex-col">
        <div className="flex flex-col gap-10  bg-[#f5f9fb] md:basis-full">
          <div className="flex flex-col bg-[#052c3d] px-8 py-10 rounded-md  m-4 ">
            <h3 class="text-tertiary text-4xl mb-4 pb-1 font-semibold text-white">
              {productData.name}
            </h3>

            <div className="flex items-center pt-1 pb-1">
              <p className="flex items-center justify-center rounded-md  object-cover  h-full bg-[#1a5767] px-5 py-2.5 text-center text-sm font-medium text-[#4cbac9] ">
                {productData.age} Years
              </p>
            </div>
            <p className="leading-6 pb-4 pt-4 text-md mb-2 text-white font-semibold ">
              {productData.description}
            </p>
            <div className="flex items-center text-white justify-between mt-8 md:text-xl ">
              {productData.isSubscription ? (
                <div className="flex items-center pl-1 pr-1 pb-1 ml-1">
                  <img
                    src="https://quest.finology.in/images/modules.svg"
                    alt=""
                  />
                  <p className="pl-1 pt-0.5  whitespace-nowrap">
                    {productData.lessons} Session
                  </p>
                </div>
              ) : (
                ""
              )}
              {productData.isSubscription ? (
                <div className="flex items-center pl-1 pr-1 pb-1 ml-1">
                  <img
                    src="https://quest.finology.in/images/time.svg"
                    alt=""
                  />
                  <p className="pl-1 pt-0.5  whitespace-nowrap">
                    {productData.hours} hrs
                  </p>
                </div>
              ) : (
                ""
              )}

              <div className="flex items-center pr-1 pb-1">
                <img
                  src="https://quest.finology.in/images/language.svg"
                  alt=""
                />
                <p className="pl-1 pt-0.5  whitespace-nowrap">
                  {productData.language}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg m-4">
            <div className="  m-5">
              <h2 className="text-3xl font-bold mb-2">Course Outline</h2>
              <div className="text-sm  mb-4 md:pt-1 md:line-height-5  pt-1 md:line-height-15">
                <p className="text-lg font-semibold mb-4">
                  What will you learn:
                </p>

                <ul className="list-disc pl-5 text-base">
                  {productData.courseOutline.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg m-4">
            <div className=" m-5 ">
              <h2 className="text-3xl font-bold mb-2">About Course</h2>
              <div
                className="  mb-4 md:pt-1 md:line-height-5  pt-1 md:line-height-15 pl-5 text-base"
                dangerouslySetInnerHTML={{ __html: productData.aboutCourse }}
              ></div>
            </div>
          </div>
        </div>
        <div className="m-4 rounded-lg  md:basis-5/12">
          <img
            className="rounded-lg"
            src={productData.images[0].url}
            alt={productData.name}
          />
          <div className=" bg-white rounded-lg p-2">
            <div class="p-5 pb-1 ">
              <div class="flex justify-center gap-5 ">
                <p>
                  <span className="text-3xl font-bold text-slate-900">
                    ₹{productData.price}
                  </span>
                  <span className="text-sm text-slate-900 line-through">
                    ₹{productData.originalPrice}
                  </span>
                </p>
                <button
                  onClick={() => addToCart()}
                  className={`flex items-center justify-center rounded-md object-cover  h-full bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300 ${
                    addedToCart ? " opacity-50" : "cursor-pointer"
                  }`}
                  disabled={addedToCart}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  {addedToCart ? "Added" : "Add to Cart"}
                </button>
              </div>
            </div>
            <div className="ml-10 my-4">
              <h2 className="text-3xl font-bold mb-2">What you will get?</h2>
              <div className="flex flex-col pt-1 gap-2">
                <div className="flex items-center">
                  {productData.whatYouWillGet.ChatSupport ? (
                    <>
                      <CheckCircleOutlineRoundedIcon />
                      <p className="pl-2 text-xs">Chat Support</p>
                    </>
                  ) : (
                    <>
                      <CancelOutlinedIcon />
                      <p className="pl-2 text-xs">Chat Support</p>
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  {productData.whatYouWillGet.GuidanceSessions ? (
                    <>
                      <CheckCircleOutlineRoundedIcon />
                      <p className="pl-2 text-xs">Guidance Sessions</p>
                    </>
                  ) : (
                    <>
                      <CancelOutlinedIcon />
                      <p className="pl-2 text-xs">Guidance Sessions</p>
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  {productData.whatYouWillGet.SessionSummary ? (
                    <>
                      <CheckCircleOutlineRoundedIcon />
                      <p className="pl-2 text-xs">Session summary</p>
                    </>
                  ) : (
                    <>
                      <CancelOutlinedIcon />
                      <p className="pl-2 text-xs">Session summary</p>
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  {productData.whatYouWillGet.ResponseSheets ? (
                    <>
                      <CheckCircleOutlineRoundedIcon />
                      <p className="pl-2 text-xs">Response Sheets</p>
                    </>
                  ) : (
                    <>
                      <CancelOutlinedIcon />
                      <p className="pl-2 text-xs">Response Sheets</p>
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  {productData.whatYouWillGet.TherapyPlans ? (
                    <>
                      <CheckCircleOutlineRoundedIcon />
                      <p className="pl-2 text-xs">Therapy Plans</p>
                    </>
                  ) : (
                    <>
                      <CancelOutlinedIcon />
                      <p className="pl-2 text-xs">Therapy Plans</p>
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  {productData.whatYouWillGet.PrintableMaterials ? (
                    <>
                      <CheckCircleOutlineRoundedIcon />
                      <p className="pl-2 text-xs">Printable Materials</p>
                    </>
                  ) : (
                    <>
                      <CancelOutlinedIcon />
                      <p className="pl-2 text-xs">Printable Materials</p>
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  {productData.whatYouWillGet.Flashcards ? (
                    <>
                      <CheckCircleOutlineRoundedIcon />
                      <p className="pl-2 text-xs">Flashcards</p>
                    </>
                  ) : (
                    <>
                      <CancelOutlinedIcon />
                      <p className="pl-2 text-xs">Flashcards</p>
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  {productData.whatYouWillGet.Notes ? (
                    <>
                      <CheckCircleOutlineRoundedIcon />
                      <p className="pl-2 text-xs">Notes</p>
                    </>
                  ) : (
                    <>
                      <CancelOutlinedIcon />
                      <p className="pl-2 text-xs">Notes</p>
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  {productData.whatYouWillGet.Consultations ? (
                    <>
                      <CheckCircleOutlineRoundedIcon />
                      <p className="pl-2 text-xs">Consultations</p>
                    </>
                  ) : (
                    <>
                      <CancelOutlinedIcon />
                      <p className="pl-2 text-xs">Consultations</p>
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  {productData.whatYouWillGet.Assessments ? (
                    <>
                      <CheckCircleOutlineRoundedIcon />
                      <p className="pl-2 text-xs">Assessments</p>
                    </>
                  ) : (
                    <>
                      <CancelOutlinedIcon />
                      <p className="pl-2 text-xs">Assessments</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <TakeReview productId={productId} />
        </div>
      </div>
      <Box
        py={4}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        bgcolor="#F3F7FC"
        alignItems="center"
        gap={2}
      >
        <Typography
          variant="h2"
          px="5px"
        >
          FAQ’s
        </Typography>
        <Typography variant="p">Need answers? Find them here</Typography>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: { xs: "95vw", md: "60vw" } }}
          gap={2}
        >
          {productData.faq.map((item, index) => (
            <Accordion
              key={item._id}
              elevation={0}
              // expanded={expandedAccordion === `panel${index + 1}`}
              // onChange={handleChange(`panel${index + 1}`)}
              sx={{
                padding: "20px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMore
                    sx={{
                      backgroundColor: "#ffb38a",
                      borderRadius: "50%",
                      width: "2rem",
                      height: "2rem",
                      "@media (min-width: 600px)": {
                        width: "3rem",
                        minWidth: "3rem",
                        height: "3rem",
                      },
                    }}
                  />
                }
                aria-label="Expand"
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Typography fontWeight={600}>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
      <ProductReview
        productId={productId}
        totalReviewCount={productData.totalReviewCount}
        averageRating={productData.averageRating}
      />

      <Footer />
    </>
  )
}

export default ProductDetail
