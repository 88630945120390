import React, { useEffect, useState } from "react"
import axios from "axios"
import { Rating } from "@mui/material"

function ProductReview({ productId, averageRating, totalReviewCount }) {
  const [reviews, setReviews] = useState([])
  const [displayedReviews, setDisplayedReviews] = useState(10) // Number of reviews initially displayed

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(`/api/reviews/${productId}`)

        if (response.status === 200 && response.data.success) {
          setReviews(response.data.reviews)
        } else {
          console.error("Failed to fetch reviews")
        }
      } catch (error) {
        console.error("Error fetching reviews", error)
      }
    }

    fetchReviews()
  }, [productId])

  const loadMoreReviews = () => {
    setDisplayedReviews((prevCount) => prevCount + 10)
  }

  return (
    <div>
      <section className="py-24 relative">
        <div className="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto">
          <div className="w-full">
            <h2 className="font-manrope font-bold text-4xl text-black mb-8 text-center">
              Our customer reviews
            </h2>
            <div className="">
              <div className="p-8 bg-amber-50 rounded-3xl flex items-center justify-center flex-col">
                <h2 className="font-manrope font-bold text-5xl text-amber-400 mb-6">
                  {averageRating}
                </h2>
                <Rating
                  name="read-only"
                  value={averageRating}
                  precision={0.5}
                  size="large"
                  readOnly
                />
                <p className="font-medium text-xl leading-8 text-gray-900 text-center">
                  {totalReviewCount} Ratings
                </p>
              </div>
            </div>

            {reviews.slice(0, displayedReviews).map((review) => (
              <div
                key={review._id}
                className="pt-11 pb-8 border-b border-gray-100 max-xl:max-w-2xl max-xl:mx-auto"
              >
                <div className="flex items-center gap-3 mb-4">
                  <Rating
                    name="read-only"
                    value={review.rating}
                    precision={0.5}
                    size="large"
                    readOnly
                  />
                </div>

                <div className="flex sm:items-center flex-col min-[400px]:flex-row justify-between gap-5 mb-4">
                  <div className="flex items-center gap-3">
                    <img
                      src="https://pagedone.io/asset/uploads/1704349572.png"
                      alt="John "
                      className="w-8 h-8"
                    />
                    <h6 className="font-semibold text-lg leading-8 text-indigo-600 ">
                      {review.user.userName}
                    </h6>
                  </div>
                  <p className="font-normal text-lg leading-8 text-gray-400">
                    {review.updatedAt}
                  </p>
                </div>
                <p className="font-normal text-lg leading-8 text-gray-400 max-xl:text-justify">
                  {review.comment}
                </p>
              </div>
            ))}

            {reviews.length > displayedReviews && (
              <div className="flex justify-center mt-8">
                <button
                  onClick={loadMoreReviews}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default ProductReview
