import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Avatar,
} from "@mui/material"
import SendIcon from "@mui/icons-material/Send"

const Community = () => {
  const [questions, setQuestions] = useState([])
  const [userQuestion, setUserQuestion] = useState("")
  const [userAnswer, setUserAnswer] = useState("")

  useEffect(() => {
    // Fetch questions from the backend when the component mounts
    axios
      .get("api/questions")
      .then((response) => setQuestions(response.data))
      .catch((error) => console.error("Error:", error))
  }, [])

  const postQuestion = () => {
    if (!userQuestion) {
      return // Don't post empty questions
    }

    // Post the new question to the backend
    axios
      .post("api/questions", {
        title: userQuestion,
        body: userQuestion,
      })
      .then((response) => {
        setQuestions([...questions, response.data])
        setUserQuestion("")
      })
      .catch((error) => console.error("Error:", error))
  }

  const postAnswer = (questionId) => {
    if (!userAnswer) {
      return // Don't post empty answers
    }

    // Post the new answer to the backend
    axios
      .post(`api/questions/${questionId}/answers`, {
        body: userAnswer,
      })
      .then((response) => {
        const updatedQuestions = questions.map((question) => {
          if (question._id === questionId) {
            return {
              ...question,
              answers: [...question.answers, response.data],
            }
          }
          return question
        })

        setQuestions(updatedQuestions)
        setUserAnswer("")
      })
      .catch((error) => console.error("Error:", error))
  }

  return (
    <Box
      p={2}
      maxWidth={600}
      margin="auto"
    >
      <Paper
        elevation={3}
        style={{ padding: "20px" }}
      >
        <Typography
          variant="h5"
          gutterBottom
        >
          Community Forum
        </Typography>

        {/* Post a Question */}
        <Box
          display="flex"
          alignItems="center"
          mb={2}
        >
          <Avatar sx={{ bgcolor: "primary.main" }}>U</Avatar>
          <TextField
            label="Post your question"
            variant="outlined"
            fullWidth
            value={userQuestion}
            onChange={(e) => setUserQuestion(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            onClick={postQuestion}
          >
            Post
          </Button>
        </Box>

        {/* Questions and Answers */}
        {questions.map((question) => (
          <Paper
            key={question._id}
            elevation={1}
            style={{ padding: "15px", marginBottom: "15px" }}
          >
            <Typography
              variant="h6"
              gutterBottom
            >
              {question.user}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
            >
              {question.title}
            </Typography>

            {question.answers.map((answer) => (
              <Box
                key={answer._id}
                mb={2}
              >
                <Typography>{answer.body}</Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => alert("Reply clicked!")}
                >
                  Reply
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => alert("Edit clicked!")}
                >
                  Edit
                </Button>
              </Box>
            ))}

            {/* Answer input */}
            <Box
              display="flex"
              alignItems="center"
              mb={2}
            >
              <Avatar sx={{ bgcolor: "primary.main" }}>U</Avatar>
              <TextField
                label="Your answer"
                variant="outlined"
                fullWidth
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                onClick={() => postAnswer(question._id)}
              >
                Answer
              </Button>
            </Box>
          </Paper>
        ))}
      </Paper>
    </Box>
  )
}

export default Community
