import { AuthContext } from "../context/AuthContext"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"

export const useLogout = () => {
  const { dispatch } = useContext(AuthContext)
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem("data")
    dispatch({ type: "LOGOUT" })

    // Redirect to the login page
    navigate("/login")
  }

  return { logout }
}
