import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { IoIosArrowRoundForward } from "react-icons/io"
import { Link } from "react-router-dom"

function CardCarousel() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: data.length > 2 ? 2 : data.length,
    slidesToScroll: 1,
    swipe: true,
    touchMove: true,
    arrows: false,
    swipeToSlide: true,
    dotsClass: "slick-dots custom-dots",
    appendDots: (dots) => (
      <div
        style={{
          display: "flex",
          position: "relative",
          margin: "0 5px",
          padding: "0",
          cursor: "pointer",
        }}
      >
        <ul
          style={{
            marginLeft: "0px",
            border: "2px",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          swipe: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          swipe: true,
        },
      },
    ],
  }

  return (
    <div className="sm:w-full md:w-full lg:w-[760px]">
      <Slider {...settings}>
        {data.map((d, index) => (
          <div
            key={d.id}
            className="relative m-1  h-[400px] flex w-full max-w-xs flex-col rounded-lg border border-gray-100 bg-white shadow-md"
          >
            <div className="p-2">
              <p className="text-md mb-5 mt-2">{d.title}</p>
              <p className="font-semibold text-lg">{d.desc}</p>
            </div>
            <Link
              to={d.link}
              target="_blank"
              rel="noopener noreferrer"
              className="absolute top-80 left-60 border border-black rounded-full px-1 py-1 hover:scale-125 transition duration-500 cursor-pointer"
            >
              <IoIosArrowRoundForward className="text-3xl" />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  )
}
const data = [
  {
    id: 1,
    title: "International Journal of Pediatric Otorhinolaryngology",
    desc: "A low-intensity training program for parents, supported by a manual focusing on developmentally appropriate play and speech-language stimulation, resulted in increased verbal interaction and changes in language input to children.",
    link: "https://www.sciencedirect.com/science/article/abs/pii/S0165587619301612",
  },
  {
    id: 2,
    title: "American Journal of Speech-Language Pathology",
    desc: "The results indicate that parent-implemented language interventions have a significant, positive impact on receptive and expressive language skills. ",
    link: "https://www.researchgate.net/publication/51037307_The_Effectiveness_of_Parent-Implemented_Language_Interventions_A_Meta-Analysis#:~:text=The%20results%20indicate%20that%20parent,outcome%20measure%20and%20comparison%20group.",
  },
  {
    id: 3,
    title: "Journal of Telemedicine and Telecare",
    desc: "Student progress reports indicated that the children made similar progress during the study whichever treatment method was used.",
    link: "https://pubmed.ncbi.nlm.nih.gov/20197354/",
  },
  {
    id: 4,
    title: "International Journal of Pediatric Otorhinolaryngology",
    desc: "The results of the study support the effectiveness of parent-implemented early intervention on positive changes in children's speech-language development and mothers' use of communication strategies.",
    link: "https://www.researchgate.net/publication/272890135_Effectiveness_of_a_Parent-Implemented_Intervention_Program_for_Young_children_with_Cleft_Palate",
  },
  {
    id: 5,
    title:
      "Parental Involvement in Speech Activities of Speech Delayed Child at Home",
    desc: "The results of the study support the effectiveness of parent-implemented early intervention on positive changes in children's speech-language development and mothers' use of communication strategies.",
    link: "https://www.researchgate.net/publication/311796430_Parent-implemented_interventions_around_the_globe",
  },
]

export default CardCarousel
