import React from "react"
import { Container } from "@mui/material"

const Loading = ({ show }) => {
  return (
    show && (
      <Container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <img
          src="/loading.gif"
          alt="Loading..."
          style={{ width: "250px", height: "250px" }}
        />
      </Container>
    )
  )
}

export default Loading
