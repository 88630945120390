import {
  Box,
  Typography,
  Stack,
  TextField,
  InputLabel,
  MenuItem,
  Checkbox,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import EmailIcon from "@mui/icons-material/Email"
import { useState, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import GoogleIcon from "@mui/icons-material/Google"
import axios from "axios"
import { AuthContext } from "../context/AuthContext"
import { successToast, errorToast } from "../utils/toastConfig"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

const genders = [
  {
    value: "Choose Gender",
    label: "Choose Gender",
  },
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
]

const SignUpCard = () => {
  const [stage, setStage] = useState(1)
  const [name, setName] = useState("")
  const [pwd, setPwd] = useState("")
  const [cnfmpwd, setCnfmpwd] = useState("")
  const [gender, setGender] = useState("")
  const [contact, setContact] = useState("")
  const [email, setEmail] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [err, setErr] = useState(false)
  const [errmsg, setErrmsg] = useState("")
  const [open, setOpen] = useState(false)
  const [isWhatsApp, setIsWhatsApp] = useState(false)
  const navigate = useNavigate()

  const { dispatch } = useContext(AuthContext)

  const [values, setValues] = useState({
    showPassword: false,
    showCnfmPassword: false,
  })

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleClickShowCnfmPassword = () => {
    setValues({
      ...values,
      showCnfmPassword: !values.showCnfmPassword,
    })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleSubmit = () => {
    if (!name || !gender || !contact || !state || !city) {
      setErr(true)
      setErrmsg("All fields must be filled!")
    } else {
      setErr(false)
      setStage((stage) => stage + 1)
    }
  }

  const handleSignup = async () => {
    if (stage === 2) {
      if (!email || !pwd || !cnfmpwd) {
        setErr(true)
        setErrmsg("All fields must be filled!")
        errorToast("All fields must be filled!")
      } else if (pwd !== cnfmpwd) {
        setErr(true)
        setErrmsg("Passwords does not match")
        errorToast("Passwords do not match")
      } else {
        setErr(false)
        try {
          const { data } = await axios.post("api/auth/signupb", {
            email: email,
            username: name,
            password: pwd,
            gender: gender,
            state: state,
            city: city,
            contact: contact,
            isWhatsApp: isWhatsApp,
          })
          if (!data) {
            setErr(true)
            setErrmsg("Internal error occurred")
            errorToast("Internal error occurred")
          } else if (!data.success) {
            setErr(true)
            setErrmsg(data.message)
            errorToast(data.message)
          } else {
            setErr(false)
            setOpen(true) // Open the dialog on success
            successToast(data.message)
          }
        } catch (error) {
          setErr(true)
          setErrmsg("An unexpected error occurred. Please try again later.")
          errorToast("An unexpected error occurred. Please try again later.")
        }
      }
    } else {
      errorToast("An unexpected error occurred. Please try again later.")
    }
  }

  const handleClose = () => {
    setOpen(false)
    navigate("/auth/login")
  }

  return (
    <Box
      height="max-content"
      ml={{ xs: 0, md: 30 }}
      bgcolor={"white"}
      padding="20px 0px"
      width={{ xs: "100vw", md: "25vw" }}
      border={{ xs: 0, md: "1px solid #999999" }}
      borderRadius={2}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h3"
            fontWeight={700}
            fontSize="32px"
          >
            Create account
          </Typography>
          <Typography
            variant="p"
            color="#3d3d3d"
            fontSize="16px"
          >
            Please enter your details
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="start"
          flexDirection="column"
          gap={1}
        >
          {stage === 1 && (
            <>
              <Box>
                <InputLabel sx={{ color: "#000000" }}>Parent Name</InputLabel>
                <TextField
                  hiddenLabel
                  placeholder="Enter Full name"
                  size="small"
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                />
              </Box>
              <Box>
                <InputLabel sx={{ color: "#000000" }}>Gender</InputLabel>
                <TextField
                  select
                  hiddenLabel
                  defaultValue="Choose Gender"
                  size="small"
                  onChange={(e) => {
                    setGender(e.target.value)
                  }}
                >
                  {genders.map((gender) => (
                    <MenuItem
                      key={gender.value}
                      value={gender.value}
                    >
                      {gender.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
              >
                <InputLabel sx={{ color: "#000000" }}>Contact</InputLabel>
                <PhoneInput
                  country={"in"}
                  value={contact}
                  onChange={(value) => setContact(value)}
                  inputProps={{
                    required: true,
                  }}
                />
                <Box
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Checkbox
                    size="small"
                    checked={isWhatsApp}
                    onChange={(e) => setIsWhatsApp(e.target.checked)}
                  />
                  <Typography
                    variant="p"
                    fontSize="12px"
                    display={"flex"}
                  >
                    This is my
                    <Typography
                      variant="p"
                      color="#24B00E"
                    >
                      WhatsApp
                    </Typography>
                    number
                  </Typography>
                </Box>
              </Box>
              <Box>
                <InputLabel sx={{ color: "#000000" }}>Country</InputLabel>
                <TextField
                  hiddenLabel
                  placeholder="Enter Country"
                  size="small"
                  onChange={(e) => {
                    setState(e.target.value)
                  }}
                />
              </Box>
              <Box>
                <InputLabel sx={{ color: "#000000" }}>City</InputLabel>
                <TextField
                  hiddenLabel
                  placeholder="Enter City"
                  size="small"
                  onChange={(e) => {
                    setCity(e.target.value)
                  }}
                />
              </Box>
              {err && (
                <Typography
                  variant="p"
                  color="red"
                >
                  {errmsg}
                </Typography>
              )}
              <Button
                variant="contained"
                fullWidth
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </>
          )}
          {stage === 2 && (
            <>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap={1}
              >
                <Box>
                  <InputLabel sx={{ color: "#000000" }}>Email</InputLabel>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Your Email"
                    size="small"
                    style={{ width: "260px" }}
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                  />
                </Box>
                <Stack sx={{ marginTop: "10px", alignItems: "start" }}>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="pwd"
                    placeholder="Enter your password"
                    type={values.showPassword ? "text" : "password"}
                    value={pwd}
                    size="small"
                    onChange={(e) => {
                      setPwd(e.target.value)
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </Stack>
                <Stack sx={{ marginTop: "10px", alignItems: "start" }}>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="cnfm-pwd"
                    placeholder="Enter your password again"
                    type={values.showCnfmPassword ? "text" : "password"}
                    value={cnfmpwd}
                    size="small"
                    onChange={(e) => {
                      setCnfmpwd(e.target.value)
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCnfmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showCnfmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </Stack>
                {err && (
                  <Typography
                    variant="p"
                    color="red"
                  >
                    {errmsg}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSignup}
                >
                  Sign Up
                </Button>
                <Typography
                  variant="p"
                  fontSize="12px"
                >
                  -------------------------- OR --------------------------
                </Typography>
                <Button
                  startIcon={<GoogleIcon />}
                  variant="outlined"
                  fullWidth
                  sx={{
                    fontSize: "12px",
                    fontWeight: "700",
                    color: "#000000",
                    border: "1px solid black",
                  }}
                >
                  Sign In with Google
                </Button>
              </Box>
            </>
          )}
        </Box>
        <Typography
          variant="p"
          color="#3d3d3d"
          fontSize="14px"
        >
          Already have an account?{" "}
          <Link to="/auth/login">
            <Button size="small">Sign In</Button>
          </Link>
        </Typography>
      </Stack>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            textAlign: "center",
            padding: "20px",
          },
        }}
      >
        <DialogTitle className="text-center">
          <div className="flex items-center justify-center mb-4">
            <div className="h-16 w-16 sm:h-24 sm:w-24 bg-gradient-to-r from-purple-600 to-indigo-600 rounded-full flex items-center justify-center text-white">
              <EmailIcon className="text-white h-8 w-8 sm:h-12 sm:w-12" />
            </div>
          </div>
          <h2 className="text-lg sm:text-xl font-bold">
            Please verify your email
          </h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You're almost there! We sent an email to <strong>{email}</strong>
            <br />
            Just click on the link in that email to complete your signup. If you
            don't see it, you may need to check your spam folder.
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default SignUpCard
