import React from "react"
import { Typography, Button, Grid, Box } from "@mui/material"
import { Link } from "react-router-dom"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"

const UnauthorizedUserCart = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="80vh"
      textAlign="center"
    >
      <ShoppingCartIcon style={{ fontSize: 64, marginBottom: "1rem" }} />
      <Typography
        variant="h4"
        gutterBottom
      >
        Your Cart is Empty
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
      >
        To add items to your cart, please sign in or sign up.
      </Typography>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Link
            to="/auth/login"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
            >
              Sign In
            </Button>
          </Link>
        </Grid>
        <Grid item>
          <Link
            to="/auth/signup"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
            >
              Sign Up
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UnauthorizedUserCart
