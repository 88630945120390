import React from "react"
import useMobile from "../../Hooks/child/useMobile"
import styles from "./Sidebar.module.css"
import SidebarItem from "./SidebarItem"

const Sidebar = () => {
  const mobile = useMobile("(max-width: 940px)")

  return (
    <section
      className={mobile ? `${styles.section} ${styles.mobile}` : styles.section}
    >
      <SidebarItem
        index={1}
        stepInfo="Child info"
      />
      <SidebarItem
        index={2}
        stepInfo="Basic Questions"
      />
      <SidebarItem
        index={3}
        stepInfo="Initial Complaint"
      />
      {/* <SidebarItem
        index={4}
        stepInfo="Summary"
      /> */}
      <SidebarItem
        index={4}
        stepInfo="Finished!"
      />
    </section>
  )
}

export default Sidebar
