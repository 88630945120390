import { Box, Typography } from "@mui/material"

const Refund = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{ width: { xs: "80vw", sm: "40vw" } }}
    >
      <Typography
        variant="h5"
        fontWeight={600}
      >
        Cancellation & Refund Policy
      </Typography>
      <Typography
        variant="p"
        color="#6A788C"
        fontWeight={500}
      >
        Last updated on Feb 18th 2024
      </Typography>
      <Typography
        variant="p"
        color="#6A788C"
        fontSize="15px"
      >
        You may cancel your Simba Speaks Subscription at any time for any or no
        reason. If you have cancelled your Simba Speaks Subscription, you will
        lose access to all Premium features you enrolled into during your Simba
        Speaks Subscription at the end of your billing period. If you choose to
        sign up as a paying member, your billing date will reset to the day you
        paid. Payments are non-refundable and there are no refunds or credits
        for partially used periods. Following any cancellation, however, you
        will continue to have the benefits of your Simba Speaks Subscription
        through the end of your current billing period.
      </Typography>
      <Typography
        variant="h6"
        fontWeight={500}
      >
        Price Changes
      </Typography>
      <Typography
        variant="p"
        color="#6A788C"
        fontSize="15px"
      >
        We reserve the right, at any time, to change any fees or charges for
        using any services provided at Simba Speaks (or to begin charging fees
        for any free service), provided that such changes will not apply to fees
        or charges paid or free services used prior to the time of the change.
        Further, any price change to your service will take effect following
        prior intimation to you.
      </Typography>
    </Box>
  )
}

export default Refund
