import Axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import CancelRoundedIcon from "@mui/icons-material/CancelRounded"

export default function Pricing() {
  const [products, setProducts] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    Axios.get("api/products")
      .then((response) => {
        setProducts(response.data)
      })
      .catch((error) => {
        console.error("Error fetching products:", error)
      })
  }, [])
  // const PRICING_DATA = products.filter((product) => product.isSubscription)
  const PRICING_DATA = products
    .filter((product) => product.isSubscription)
    .sort(
      (a, b) =>
        (a.subscriptionOrder || Infinity) - (b.subscriptionOrder || Infinity)
    )
  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`)
  }

  return (
    <div>
      <div
        className="bg-white lg:p-20 "
        style={{ height: `calc(100vh - 72px)` }}
      >
        <container className="lg:scale-90 lg:mt-12 font-sans lg:bg-transparent flex flex-col lg:flex-row absolute justify-center lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 px-5 xl:px-0 py-8 lg:py-0 w-full gap-6 items-center lg:items-stretch ">
          {PRICING_DATA.map((data, index) => (
            <div
              key={index}
              className="relative"
            >
              <div className="max-w-sm xl:w-[340px]   p-5 bg-[#E9EAE0] group h-full rounded-2xl lg:hover:-translate-y-2 ease-in  hover:bg-[#EDC1BB]  border xl:border-none border-[#0B0641] hover:border-transparent transition-all duration-300 ">
                <div className="flex flex-row gap-5 items-center text-center justify-left">
                  <span className="text-3xl font-bold text-center">
                    {data.name}
                  </span>
                </div>
                <div className="border-b border-black mt-3"></div>
                {data.benefits[0].split(",").map((benefit, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-2.5 items-start mt-6 text-left text-lg"
                  >
                    <div className="pt-1 shrink-0">
                      <RightIcon />
                    </div>
                    <span>{benefit.trim()}</span>
                  </div>
                ))}
                {data?.notBenefits?.length > 0 &&
                  data.notBenefits[0].split(",").map((benefit, index) => (
                    <div
                      key={index}
                      className="flex flex-row gap-2.5 items-start mt-6 text-left text-lg"
                    >
                      <div className="pb-1 shrink-0">
                        <CancelRoundedIcon />
                      </div>
                      <span>{benefit.trim()}</span>
                    </div>
                  ))}
                <div className="border border-dashed border-[#A9A9AA] tracking-widest my-4" />
                <div className="h-36">
                  <div className="bottom-6 left-6 right-6 absolute">
                    <div className="flex justify-start items-baseline">
                      <p>
                        <span className="text-3xl font-bold text-[32px]">
                          ₹{data.price}
                        </span>
                        <span className="text-sm line-through">
                          ₹{data.originalPrice}
                        </span>
                      </p>
                    </div>
                    <button
                      onClick={() => handleProductClick(data._id)}
                      className="w-full px-4 py-3 bg-[#FFF5FA] text-[#FF1D89] rounded-xl mt-6 font-semibold text-xl transition-all duration-300"
                    >
                      Choose
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </container>
      </div>
    </div>
  )
}

const RightIcon = () => {
  return (
    <svg
      className="fill-current"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.0001 0.00012207C4.48608 0.00012207 7.62939e-05 4.48612 7.62939e-05 10.0001C7.62939e-05 15.5141 4.48608 20.0001 10.0001 20.0001C15.5141 20.0001 20.0001 15.5141 20.0001 10.0001C20.0001 4.48612 15.5141 0.00012207 10.0001 0.00012207ZM8.00108 14.4131L4.28808 10.7081L5.70008 9.29212L7.99908 11.5871L13.2931 6.29312L14.7071 7.70712L8.00108 14.4131Z" />
    </svg>
  )
}
